import React, { Component } from "react";
import { Radio } from "antd";

import { AssetBreadcrumb } from "@cognite/gearbox";

import { FaBuilding } from "react-icons/fa";

// import { FontAwesomeIcon } from 'react-fontawesome';
// import BuildingStyle from 'font-awesome/css/font-awesome.css'

export default class HeaderMainCustomBoard extends Component {
  render() {
    return (
      <header className="info-header">
        <div className="info-header-name-button">
          <div className="icon-inline" style={{ display: "flex" }}>
            <FaBuilding style={{ marginRight: "15px", marginTop: "2px" }} />
            <AssetBreadcrumb assetId={this.props.assetId} maxLength={5} />
          </div>

          <Radio.Group onChange={this.props.onChange}>
            <Radio.Button value="EEP">main board</Radio.Button>
            <Radio.Button value="user">custom</Radio.Button>
          </Radio.Group>
        </div>
      </header>
    );
  }
}
