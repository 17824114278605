import React, { Component } from "react";
import { Table } from "antd";
import { TimeseriesChart, AssetBreadcrumb } from "@cognite/gearbox";
import moment from "moment";
import DateRange from "./../DateRange";

import { AiOutlineStock } from "react-icons/ai";
const ruler = {
  visible: true,
  yLabel: (point) => `${Number.parseFloat(point.value).toFixed(3)}`,
  timeLabel: (point) =>
    moment.unix(point.timestamp / 1000).format("MM/DD/YYYY"),
};
export default class Timeseries extends Component {
  constructor(props) {
    super(props);

    this.columnsDetails = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        align: "left",
        title: "Age",
        dataIndex: "value",
        key: "value",
      },
    ];

    this.state = {
      infographicsList: null,
      loading: true,
      infographicName: null,
      imageUrl: null,
      infographicObj: null,
      startDate: Date.now() - 30 * 24 * 60 * 60 * 1000,
      endDate: Date.now(),
    };
  }
  range = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate,
    });
  };
  render() {
    const { infoRightBar, timeseriesObj, sensorObj } = this.props;
    var heightRightBar = infoRightBar - 114;
    var unit = timeseriesObj.unit === undefined ? "" : timeseriesObj.unit;
    return (
      <div>
        <div className="timeseries-header">
          <section>
            <AiOutlineStock
              style={{
                backgroundColor: " rgb(245, 245, 245)",
                fontSize: "24px",
                marginTop: "11px",
              }}
            />
            <div className="asset-name">
              <div className="sensor-name">
                <div
                  class="text-font editable-text-disabled"
                  data-testid="editable-text-disabled"
                >
                  {timeseriesObj.name}
                </div>
              </div>
              <div>
                <AssetBreadcrumb
                  onBreadcrumbClick={this.onBreadcrumbClick}
                  assetId={timeseriesObj.assetId}
                  maxLength={10}
                />
              </div>
            </div>
          </section>
          <section>
            <div style={{ marginRight: "24px" }}>
              <DateRange range={this.range} />
            </div>
          </section>
        </div>

        <div
          className="info-content-wrapper"
          style={{ height: heightRightBar + "px" }}
        >
          <div className="info-content-left timeseries-chart">
            <TimeseriesChart
              ruler={ruler}
              yAxisDisplayMode={"ALL"}
              timeseriesIds={[timeseriesObj.id]}
              startTime={this.state.startDate}
              endTime={this.state.endDate}
              zoomable={true}
              contextChart={true}
              height={heightRightBar}
            />
          </div>
          <div className="info-content-right divider">
            <div
              className="info-right-wrapper"
              style={{ width: "100%", height: heightRightBar + "px" }}
            >
              <div className="grid-wrapper">
                <div className="display-grid-name">
                  <div className="title">Name</div>
                  {timeseriesObj.name}
                </div>
                <div className="display-grid-name">
                  <div className="title">Latest reading</div>
                  <h3 className="latest-reading">
                    <p className="sensor-value">
                      {" "}
                      {sensorObj.value.toFixed(3)}
                    </p>
                    <span className="unit">{unit}</span>
                  </h3>
                  {moment(sensorObj.timestamp).format("MMM DD, YYYY HH:mm:ss")}
                </div>
                <div className="display-grid-name">
                  <div className="title">Description</div>
                  {timeseriesObj.description}
                </div>
                <div className="display-grid-name">
                  <div className="title">Asset Path</div>
                  <AssetBreadcrumb
                    onBreadcrumbClick={this.onBreadcrumbClick}
                    assetId={timeseriesObj.assetId}
                    maxLength={4}
                  />
                </div>

                <h3 className="details">Metadata</h3>
                <Table
                  columns={this.columnsDetails}
                  dataSource={metadataDetails(timeseriesObj.metadata)}
                  pagination={false}
                  fixed={false}
                  showHeader={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function metadataDetails(metadataObj) {
  var data = [];
  var keys = [];

  for (var key in metadataObj) {
    keys.push(key);
  }

  for (var i = keys.length - 1; i >= 0; i--) {
    var value = metadataObj[keys[i]];
    data.push({
      key: `${[keys[i]]}`,
      name: `${[keys[i]]}`,
      value: `${value}`,
    });
  }
  return data;
}
