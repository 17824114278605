import React, { Component } from "react";
import { Card } from "antd";
import { navigate } from "@reach/router";

import { convertArrayBufferToImage } from "../ClientHelperFun";

const { Meta } = Card;

export default class ThreeDimensional extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelsObj: null,
      loading: true,
    };
    //this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let thumbnailUrl = "";
    const models3D = await this.props.client.models3D.list({ published: true });

    const tmpList = await Promise.all(
      models3D.items.map(async (item) => {
        const revisions3D = this.props.client.revisions3D.list(item.id);
        const data = revisions3D.items[0];

        const file = await this.props.client.files3D
          .retrieve(data.thumbnailThreedFileId)
          .catch((error) => {
            Promise.resolve(error).then((e) => {
              return {
                type: "failure",
                apiResponse: e,
                apiMessage: "System encountered error. Please try again later.",
              };
            });
          });
        if (file) {
          let arrayBufferView = new Uint8Array(file);
          let blob = new Blob([arrayBufferView], { type: "image/jpeg" });
          let urlCreator = window.URL || window.webkitURL;
          let imageUrl = urlCreator.createObjectURL(blob);
          thumbnailUrl = convertArrayBufferToImage(file);
        } else {
          thumbnailUrl = "../../img/defaultModel.png";
        }
        return {
          name: item.name,
          assetMappingCount: data.assetMappingCount,
          camera: data.camera,
          fileId: data.fileId,
          revisionId: data.id,
          revisionsMetadata: data.metadata,
          published: data.published,
          status: data.status,
          thumbnailThreedFileId: data.thumbnailThreedFileId,
          modelId: item.id,
          createdTime: item.createdTime,
          metadata: item.metadata,
          thumbnailURL: thumbnailUrl,
        };
      })
    );
    this.setState({ modelsObj: tmpList, loading: false });
  }

  handleChange(value) {
    this.props.ThreeDdataObject(value);
    navigate(`/3d/${value.modelId}/${value.revisionId}`);
  }
  render() {
    const { modelsObj, loading } = this.state;

    const modelList =
      modelsObj &&
      modelsObj.map((model) => {
        return (
          <Card
            key={model.name}
            style={{ width: 400, marginRight: "30px", cursor: "pointer" }}
            cover={
              <img
                alt={model.thumbnailURL}
                src={model.thumbnailURL}
                style={{ height: "225px", objectFit: "cover", width: "100%" }}
              />
            }
            onClick={() => this.handleChange(model)}
          >
            <Meta title={model.name} description="Click to view" />
          </Card>
        );
      });

    return (
      <>
        <header className="info-header">
          <div className="info-header-name-button">
            <h2>3D</h2>
          </div>
        </header>
        <div
          className="content-wrapper"
          style={{ display: "flex", height: "100%" }}
        >
          {loading !== true ? (
            modelList
          ) : (
            <Card
              style={{ width: 400 }}
              loading={loading}
              cover={
                <img
                  alt={"defaultModel"}
                  src="../../img/defaultModel.png"
                  style={{ height: "225px", objectFit: "cover", width: "100%" }}
                />
              }
            >
              <Meta title="Card title" description="This is the description" />
            </Card>
          )}
        </div>
      </>
    );
  }
}
