import React, { Component } from "react";
import { AssetBreadcrumb, AssetMeta } from "@cognite/gearbox";
import { Layout } from "antd";
import moment from "moment";
import { navigate } from "@reach/router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const { Content, Footer } = Layout;

export default class AssetMetaChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onBreadcrumbClick = (asset, depth) => {
    if (depth === 0) {
      navigate(`/assets/${asset.id}`);
    } else {
      navigate(`/assetMeta/${asset.id}`);
    }
  };

  render() {
    const { rootId } = this.props;
    return (
      <Content className="main-content ant-layout-content">
        <div className="content-wrapper">
          <Row>
            <Col md={12}>
              <AssetBreadcrumb
                onBreadcrumbClick={this.onBreadcrumbClick}
                assetId={rootId}
                maxLength={5}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AssetMeta
                defaultActiveKey={["1"]}
                assetId={rootId}
                customSpinner={<div>Loading...</div>}
                timeseriesProps={{
                  defaultTimePeriod: "lastMonth",
                }}
                liveUpdate={true}
              />
            </Col>
          </Row>
        </div>
        <Footer style={{ textAlign: "center" }}>
          <small>
            &copy; Copyright {moment().format("YYYY")}, Energima Energy Platform
            AS
          </small>
        </Footer>
      </Content>
    );
  }
}
