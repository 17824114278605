import React, { Component } from "react";
import { Button, DatePicker, Menu, Dropdown } from "antd";
import moment from "moment";

import { CalendarOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

export default class DateRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      visible: false,
      datePicker: false,
      datePickerName: "LAST MONTH",
      value: [],
      startDate: moment(),
      endDate: moment(),
    };
  }
  fromString = (param) => {
    var query = param.currentTarget.id;
    var datePickerName = query.replace(/_/g, " ").toUpperCase();
    this.setState({ datePickerName });

    switch (query) {
      case "last_5_minutes":
        var startDate = moment()
          .subtract(5, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        var endDate = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(startDate).unix() * 1000,
          moment(endDate).unix() * 1000
        );
        this.setState({
          startDate: moment(startDate).unix() * 1000,
          endDate: moment(endDate).unix() * 1000,
        });
        break;
      case "last_10_minutes":
        var start_10_minutes = moment()
          .subtract(10, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_10_minutes = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_10_minutes).unix() * 1000,
          moment(end_10_minutes).unix() * 1000
        );
        this.setState({
          startDate: moment(start_10_minutes).unix() * 1000,
          endDate: moment(end_10_minutes).unix() * 1000,
        });
        break;
      case "last_30_minutes":
        var start_30_minutes = moment()
          .subtract(30, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_30_minutes = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_30_minutes).unix() * 1000,
          moment(end_30_minutes).unix() * 1000
        );
        this.setState({
          startDate: moment(start_30_minutes).unix() * 1000,
          endDate: moment(end_30_minutes).unix() * 1000,
        });
        break;
      case "last_1_hour":
        var start_1_hour = moment()
          .subtract(1, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_1_hour = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_1_hour).unix() * 1000,
          moment(end_1_hour).unix() * 1000
        );
        this.setState({
          startDate: moment(start_1_hour).unix() * 1000,
          endDate: moment(end_1_hour).unix() * 1000,
        });
        break;
      case "last_6_hours":
        var start_6_hour = moment()
          .subtract(6, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_6_hour = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_6_hour).unix() * 1000,
          moment(end_6_hour).unix() * 1000
        );
        this.setState({
          startDate: moment(start_6_hour).unix() * 1000,
          endDate: moment(end_6_hour).unix() * 1000,
        });
        break;
      case "last_12_hours":
        var start_12_hour = moment()
          .subtract(12, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_12_hour = moment().format("YYYY-MM-DD HH:mm:ss");

        this.props.range(
          moment(start_12_hour).unix() * 1000,
          moment(end_12_hour).unix() * 1000
        );
        this.setState({
          startDate: moment(start_12_hour).unix() * 1000,
          endDate: moment(end_12_hour).unix() * 1000,
        });
        break;
      case "last_24_hours":
        var start_24_hour = moment()
          .subtract(24, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_24_hour = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_24_hour).unix() * 1000,
          moment(end_24_hour).unix() * 1000
        );
        this.setState({
          startDate: moment(start_24_hour).unix() * 1000,
          endDate: moment(end_24_hour).unix() * 1000,
        });
        break;
      case "last_week":
        var start_week = moment()
          .subtract(1, "week")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_week = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_week).unix() * 1000,
          moment(end_week).unix() * 1000
        );
        this.setState({
          startDate: moment(start_week).unix() * 1000,
          endDate: moment(end_week).unix() * 1000,
        });
        break;
      case "last_2_weeks":
        var start_2_week = moment()
          .subtract(2, "weeks")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_2_week = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_2_week).unix() * 1000,
          moment(end_2_week).unix() * 1000
        );
        this.setState({
          startDate: moment(start_2_week).unix() * 1000,
          endDate: moment(end_2_week).unix() * 1000,
        });
        break;
      case "last_month":
        var start_month = moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_month = moment().format("YYYY-MM-DD HH:mm:ss");

        this.props.range(
          moment(start_month).unix() * 1000,
          moment(end_month).unix() * 1000
        );
        this.setState({
          startDate: moment(start_month).unix() * 1000,
          endDate: moment(end_month).unix() * 1000,
        });
        break;
      case "last_3_months":
        var start_3_month = moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_3_month = moment().format("YYYY-MM-DD HH:mm:ss");

        this.props.range(
          moment(start_3_month).unix() * 1000,
          moment(end_3_month).unix() * 1000
        );
        this.setState({
          startDate: moment(start_3_month).unix() * 1000,
          endDate: moment(end_3_month).unix() * 1000,
        });
        break;
      case "last_6_months":
        var start_6_month = moment()
          .subtract(6, "months")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_6_month = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_6_month).unix() * 1000,
          moment(end_6_month).unix() * 1000
        );
        this.setState({
          startDate: moment(start_6_month).unix() * 1000,
          endDate: moment(end_6_month).unix() * 1000,
        });
        break;
      case "last_year":
        var start_year = moment()
          .subtract(1, "year")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_year = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_year).unix() * 1000,
          moment(end_year).unix() * 1000
        );
        this.setState({
          startDate: moment(start_year).unix() * 1000,
          endDate: moment(end_year).unix() * 1000,
        });
        break;
      case "last_2_years":
        var start_2_year = moment()
          .subtract(2, "years")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_2_year = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_2_year).unix() * 1000,
          moment(end_2_year).unix() * 1000
        );
        this.setState({
          startDate: moment(start_2_year).unix() * 1000,
          endDate: moment(end_2_year).unix() * 1000,
        });
        break;
      case "today":
        var start_today = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
        var end_today = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_today).unix() * 1000,
          moment(end_today).unix() * 1000
        );
        this.setState({
          startDate: moment(start_today).unix() * 1000,
          endDate: moment(end_today).unix() * 1000,
        });
        break;
      case "today_until_now":
        var start_today_until_now = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_today_until_now = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_today_until_now).unix() * 1000,
          moment(end_today_until_now).unix() * 1000
        );
        this.setState({
          startDate: moment(start_today_until_now).unix() * 1000,
          endDate: moment(end_today_until_now).unix() * 1000,
        });
        break;
      case "yesterday":
        var start_yesterday = moment()
          .subtract(1, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_yesterday = moment()
          .subtract(1, "days")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_yesterday).unix() * 1000,
          moment(end_yesterday).unix() * 1000
        );
        this.setState({
          startDate: moment(start_yesterday).unix() * 1000,
          endDate: moment(end_yesterday).unix() * 1000,
        });
        break;
      case "yesterday_until_now":
        var start_yesterday_until_now = moment()
          .subtract(1, "day")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_yesterday_until_now = moment().format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_yesterday_until_now).unix() * 1000,
          moment(end_yesterday_until_now).unix() * 1000
        );
        this.setState({
          startDate: moment(start_yesterday_until_now).unix() * 1000,
          endDate: moment(end_yesterday_until_now).unix() * 1000,
        });
        break;
      case "day_before_yesterday":
        var start_day_before_yesterday = moment()
          .subtract(2, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_day_before_yesterday = moment()
          .subtract(2, "days")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_day_before_yesterday).unix() * 1000,
          moment(end_day_before_yesterday).unix() * 1000
        );
        this.setState({
          startDate: moment(start_day_before_yesterday).unix() * 1000,
          endDate: moment(end_day_before_yesterday).unix() * 1000,
        });
        break;
      case "this_week":
        var start_this_week = moment()
          .startOf("week")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_this_week = moment()
          .endOf("week")
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_this_week).unix() * 1000,
          moment(end_this_week).unix() * 1000
        );
        this.setState({
          startDate: moment(start_this_week).unix() * 1000,
          endDate: moment(end_this_week).unix() * 1000,
        });
        break;
      case "previous_week":
        var start_previous_week = moment()
          .subtract(1, "weeks")
          .startOf("week")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_previous_week = moment()
          .subtract(1, "weeks")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_previous_week).unix() * 1000,
          moment(end_previous_week).unix() * 1000
        );
        this.setState({
          startDate: moment(start_previous_week).unix() * 1000,
          endDate: moment(end_previous_week).unix() * 1000,
        });
        break;
      case "this_month":
        var start_this_month = moment()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_this_month = moment()
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_this_month).unix() * 1000,
          moment(end_this_month).unix() * 1000
        );
        this.setState({
          startDate: moment(start_this_month).unix() * 1000,
          endDate: moment(end_this_month).unix() * 1000,
        });
        break;
      case "previous_month":
        var start_previous_month = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_previous_month = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_previous_month).unix() * 1000,
          moment(end_previous_month).unix() * 1000
        );
        this.setState({
          startDate: moment(start_previous_month).unix() * 1000,
          endDate: moment(end_previous_month).unix() * 1000,
        });
        break;
      case "this_year":
        var start_this_year = moment()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_this_year = moment()
          .endOf("year")
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_this_year).unix() * 1000,
          moment(end_this_year).unix() * 1000
        );
        this.setState({
          startDate: moment(start_this_year).unix() * 1000,
          endDate: moment(end_this_year).unix() * 1000,
        });
        break;
      case "previous_year":
        var start_previous_year = moment()
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD HH:mm:ss");
        var end_previous_year = moment()
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD HH:mm:ss");
        this.props.range(
          moment(start_previous_year).unix() * 1000,
          moment(end_previous_year).unix() * 1000
        );
        this.setState({
          startDate: moment(start_previous_year).unix() * 1000,
          endDate: moment(end_previous_year).unix() * 1000,
        });
        break;
      default:
        console.log("value of i is not equal to any given values");
        break;
    }
  };

  handleChange = (value) => {
    this.setState({
      startDate: value[0],
      endDate: value[1],
      value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  showDatePicker = () => {
    this.setState({
      datePicker: !this.state.datePicker,
    });
  };
  expand = () => {
    if (this.state.datePicker === true) {
      this.setState({
        datePicker: false,
      });
    }
  };

  render() {
    const menu = (
      <Menu>
        <div className="date-picker">
          <div className="date-picker-header">
            <h4>Date range</h4>
          </div>
          <div>
            <RangePicker
              placeholder={["Start month", "End month"]}
              showTime={true}
              format="YYYY-MM-DD HH:mm:ss"
              value={[moment(this.state.startDate), moment(this.state.endDate)]}
              //value={this.state.value}
              onChange={this.handleChange}
              onPanelChange={this.handlePanelChange}
            />
          </div>
          <div className="date-picker-range">
            <div>
              <div
                tabindex="0"
                id="last_5_minutes"
                onClick={this.fromString}
                className="date"
              >
                Last 5 minutes
              </div>
              <div
                tabindex="0"
                id="last_10_minutes"
                onClick={this.fromString}
                className="date"
              >
                Last 10 minutes
              </div>
              <div
                tabindex="0"
                id="last_30_minutes"
                onClick={this.fromString}
                className="date"
              >
                Last 30 minutes
              </div>
              <div
                tabindex="0"
                id="last_1_hour"
                onClick={this.fromString}
                className="date"
              >
                Last 1 hour
              </div>
              <div
                tabindex="0"
                id="last_6_hours"
                onClick={this.fromString}
                className="date"
              >
                Last 6 hour
              </div>
              <div
                tabindex="0"
                id="last_12_hours"
                onClick={this.fromString}
                className="date"
              >
                Last 12 hour
              </div>
              <div
                tabindex="0"
                id="last_24_hours"
                onClick={this.fromString}
                className="date"
              >
                Last 24 hour
              </div>
            </div>
            <div>
              <div
                tabindex="0"
                id="last_week"
                onClick={this.fromString}
                className="date"
              >
                Last week
              </div>
              <div
                tabindex="0"
                id="last_2_weeks"
                onClick={this.fromString}
                className="date"
              >
                Last 2 weeks
              </div>
              <div
                tabindex="0"
                id="last_month"
                onClick={this.fromString}
                className="date"
              >
                Last month
              </div>
              <div
                tabindex="0"
                id="last_3_months"
                onClick={this.fromString}
                className="date"
              >
                Last 3 months
              </div>
              <div
                tabindex="0"
                id="last_6_months"
                onClick={this.fromString}
                className="date"
              >
                Last 6 months
              </div>
              <div
                tabindex="0"
                id="last_year"
                onClick={this.fromString}
                className="date"
              >
                Last year
              </div>
              <div
                tabindex="0"
                id="last_2_years"
                onClick={this.fromString}
                className="date"
              >
                Last 2 years
              </div>
            </div>
            <div>
              <div
                tabindex="0"
                id="today"
                onClick={this.fromString}
                className="date"
              >
                Today
              </div>
              <div
                tabindex="0"
                id="today_until_now"
                onClick={this.fromString}
                className="date"
              >
                Today until now
              </div>
              <div
                tabindex="0"
                id="yesterday"
                onClick={this.fromString}
                className="date"
              >
                Yesterday
              </div>
              <div
                tabindex="0"
                id="yesterday_until_now"
                onClick={this.fromString}
                className="date"
              >
                Yesterday until now
              </div>
              <div
                tabindex="0"
                id="day_before_yesterday"
                onClick={this.fromString}
                className="date"
              >
                Day before Yesterday
              </div>
            </div>
            <div>
              <div
                tabindex="0"
                id="this_week"
                onClick={this.fromString}
                className="date"
              >
                This week
              </div>
              <div
                tabindex="0"
                id="previous_week"
                onClick={this.fromString}
                className="date"
              >
                Previous week
              </div>
              <div
                tabindex="0"
                id="this_month"
                onClick={this.fromString}
                className="date"
              >
                This month
              </div>
              <div
                tabindex="0"
                id="previous_month"
                onClick={this.fromString}
                className="date"
              >
                Previous month
              </div>
              <div
                tabindex="0"
                id="this_year"
                onClick={this.fromString}
                className="date"
              >
                This year
              </div>
              <div
                tabindex="0"
                id="previous_year"
                onClick={this.fromString}
                className="date"
              >
                Previous year
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );

    return (
      <>
        <Dropdown overlay={menu} placement="topRight">
          <Button>
            <CalendarOutlined />
            {this.state.datePickerName}
          </Button>
        </Dropdown>
      </>
    );
  }
}
