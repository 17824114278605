import React, { Component } from "react";
import { SketchPicker } from "react-color";
import {
  Input,
  Switch,
  Select,
  Col,
  Row,
  Form,
  message,
  notification,
  Button,
  Tabs,
} from "antd";
//import { SketchPicker } from 'react-color'
import {
  TimeseriesSearch,
  TimeseriesChart,
  AssetBreadcrumb,
  TimeseriesPreview,
} from "@cognite/gearbox";
import DateRange from "./../DateRange";
//import SelectedItems from "./SelectedItems";
import moment from "moment";
import firebase from "../../Firebase";
import { AiOutlineStock } from "react-icons/ai";
import {
  SmileOutlined,
  SaveOutlined,
  TagsOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { getExternalIdsWithSameTimeseriesIds } from "../../ClientHelperFun";
import { getColorByString } from "../../utils/colors";
import UnitList from "./UnitList";

const customStyles = {
  buttonRow: { marginTop: "15px" },
  selectAllButton: { display: "none" },
  selectNoneButton: {
    backgroundColor: "#5f9ea0",
    display: "none",
  },
};
const { TabPane } = Tabs;
const menuConfig = {
  edit: "Edit item",
  remove: "Delete item",
};

const ruler = {
  visible: true,
  yLabel: (point) => `${Number.parseFloat(point.value).toFixed(3)}`,
  timeLabel: (point) =>
    moment.unix(point.timestamp / 1000).format("MM/DD/YYYY"),
};
const { Option } = Select;
const UNIT = [
  "kWh",
  "V",
  "\u00b0C",
  "mm",
  "cm",
  "m/s",
  "octas",
  "ppm",
  "ppb",
  "%",
  "-",
  "open/closed",
  "on/off",
  "alarm/ok",
  "hPa",
  "mBar",
  "Pa",
  "W/(m3/s)",
  "kW/(m3/s)",
  "W/(l/s)",
  "m3/h",
  "l/s",
  "Bq/m3",
  "Hz",
];
export default class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeseriesName: "",
      color: "#5f9ea0",
      timeseriesId: null,
      timeseriesColor: {},
      minMaxMap: { 123: [-130, 130] },
      error: null,
      timeseriesUnit: null,
      editable: false,

      selectedItems: [],
      isSave: false,
      isChart: false,
      toggleMenu: false,
      timeseriesIds: [],
      loading: false,
      timeseriesObjectList: [],
      background: "#397983",
      sensorList: [],
      defaultPositionMap: [],
      startDate: Date.now() - 30 * 24 * 60 * 60 * 1000,
      endDate: Date.now(),

      switched: false,
    };
    this.handleMinMax = this.handleMinMax.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInfographicName = this.handleInfographicName.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleClick = (timeserieId) => {};
  handleLinkClick = (timeserieId, datapoint) => {};
  range = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate,
    });
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  handleSubmit(e) {
    e.preventDefault();
    if (this.state.timeseriesName === "") {
      notification.error({
        message: "Error",
        description: "Edit the title of the timeseries before saving.",
        duration: 5,
      });
      return;
    }
    if (this.state.timeseriesIds.length === 0) {
      notification.error({
        message: "Error",
        description: "You need to add some tags to save the timeseries.",
        duration: 5,
      });
      return;
    }
    const tmpObj = {
      type: 2,
      name: this.state.timeseriesName,
      userID: firebase.auth().currentUser.uid,
      user: this.props.user,
      tags: this.state.timeseriesObjectList,
      timeseriesIds: this.state.timeseriesIds,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      zoom: this.zoomable(this.state.timeseriesObjectList),
      created: moment().format("MMMM Do YYYY, HH:mm:ss "),
      modified: moment().format("MMMM Do YYYY, HH:mm:ss "),
      timeseriesColor: this.state.timeseriesColor,
      minMaxMap: this.state.minMaxMap,
      switched: this.state.switched,
    };

    const ref = firebase.database().ref(`board/${this.props.rootId}`);
    ref.push(tmpObj, function (error) {
      if (error) {
        // The write failed...
        message.info("Error has occured during saving process!");
      } else {
        // Data saved successfully!
        notification.open({
          message: "Data has been saved succesfully",
          description: "",
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          duration: 3,
        });
      }
    });
  }
  handleUpdate(e) {
    const { editChart } = this.props;
    const { switched } = this.state;
    e.preventDefault();

    if (this.state.timeseriesName === "") {
      notification.error({
        message: "Error",
        description: "Edit the title of the timeseries before saving.",
        duration: 5,
      });
      return;
    }
    if (this.state.timeseriesIds.length === 0) {
      notification.error({
        message: "Error",
        description: "You need to add some tags to save the timeseries.",
        duration: 5,
      });
      return;
    }
    const tmpObj = {
      type: 2,
      name: this.state.timeseriesName,
      userID: firebase.auth().currentUser.uid,
      user: this.props.user,
      tags: this.state.timeseriesObjectList,
      timeseriesIds: this.state.timeseriesIds,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      created: this.props.editChart.created,
      modified: moment().format("MMMM Do YYYY, HH:mm:ss "),
      timeseriesColor: this.state.timeseriesColor,
      minMaxMap: this.state.minMaxMap,
      switched: switched === undefined ? editChart.switched : switched,
    };

    const ref = firebase
      .database()
      .ref(`board/${this.props.rootId}/${this.props.editChart.itemID}`);
    ref.set(tmpObj, function (error) {
      if (error) {
        // The write failed...
        message.info("Error has occured during saving process!");
      } else {
        // Data saved successfully!
        notification.open({
          message: "Data has been saved succesfully",
          description: "",
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          duration: 3,
        });
      }
    });
  }
  componentDidMount() {
    const { switched } = this.state;
    const editable = this.props.editChart === undefined ? false : true;

    if (editable) {
      const newState = {
        ...this.state,
        timeseriesName: this.props.editChart.name,
        timeseriesObjectList: this.props.editChart.tags,
        timeseriesIds: this.props.editChart.timeseriesIds,
        startDate: this.props.editChart.startDate,
        endDate: this.props.editChart.endDate,
        timeseriesColor: this.props.editChart.timeseriesColor,
        minMaxMap: this.props.editChart.minMaxMap,
        switched:
          this.props.editChart.switched === undefined
            ? switched
            : this.props.editChart.switched,
        editable,
      };
      this.setState(newState);
    }
  }
  toggleMenu = () => {
    this.setState({
      toggleMenu: true,
      isActive: false,
    });
  };
  //(newTimeseriesIds: number[], selectedTimeseries: GetTimeSeriesMetadataDTO)
  onTimeserieSelectionChange = (
    timeseriesIds,
    timeseriesMetadata: GetTimeSeriesMetadataDTO
  ) => {
    console.log(
      "onTimeserieSelectionChange ",
      timeseriesIds,
      timeseriesMetadata
    );
    const { timeseriesObjectList, timeseriesColor } = this.state;
    let id = timeseriesMetadata.id;
    let color = getColorByString(id.toString());
    let unit =
      timeseriesMetadata.unit !== null && timeseriesMetadata.unit !== undefined
        ? timeseriesMetadata.unit
        : null;

    var isColor = this.findIndexInObject(id, timeseriesColor);
    if (isColor) {
      timeseriesColor[id] = color;
      this.setState({ timeseriesColor });
    } else {
      Object.assign(timeseriesColor, { [id]: color });
    }
    timeseriesObjectList.push({
      id: id,
      color: color,
      timeseriesUnit: timeseriesMetadata.unit,
      externalId: timeseriesMetadata.externalId,
      name: timeseriesMetadata.name,
      units: { [id]: unit },
      timeseriesColor: { [id]: `${color}` },
      yAxisPlacement: "RIGHT",
      switched: !this.state.switched,
    });

    var tags = getExternalIdsWithSameTimeseriesIds(
      timeseriesIds,
      timeseriesObjectList
    );
    console.log("timeseriesObjectList ", timeseriesObjectList);
    console.log("tags ", tags);
    this.setState({
      timeseriesIds,
      timeseriesObjectList: tags,
      min: null,
      max: null,
      error: null,
      timeseriesId: null,
      switched: false,
    });
  };
  filterRule = (timeseries) => !timeseries.isString;

  findIndexInObject = (id, timeseriesObjectList) => {
    for (var index in timeseriesObjectList) {
      if (timeseriesObjectList[index].id === id) {
        return true;
      }
    }
    return false;
  };

  onMenuClick(e, timeseries) {
    const { timeseriesIds, timeseriesObjectList } = this.state;

    const timeseriesId = timeseries.id;
    const timeseriesColor = timeseries.color;
    const timeseriesUnit = timeseries.timeseriesUnit;
    if (e === "remove") {
      const index = timeseriesIds.indexOf(timeseriesId);
      if (index > -1) {
        timeseriesIds.splice(index, 1);
      }
      const updatedTimeseriesObjectList = timeseriesObjectList.filter(function (
        item
      ) {
        return item.id !== timeseriesId;
      });
      this.setState({
        timeseriesObjectList: updatedTimeseriesObjectList,
        timeseriesIds,
      });
      console.log("timeseries ", timeseries, " e ", e);
    } else {
      this.setState({
        timeseriesId: timeseriesId,
        background: timeseriesColor,
        timeseriesUnit: timeseriesUnit,
      });
    }
  }

  switchYaxies = (checked) => {
    const newState = { ...this.state, switched: checked };
    this.setState(newState);
    const { timeseriesObjectList, timeseriesId } = newState;

    for (var i in timeseriesObjectList) {
      if (timeseriesObjectList[i].id === timeseriesId) {
        timeseriesObjectList[i].switched = checked;
        this.setState({ timeseriesObjectList });
        break; //Stop this loop, we found it!
      }
    }
    this.setState({ timeseriesObjectList });
  };
  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
    const {
      timeseriesId,
      timeseriesObjectList,
      background,
      timeseriesColor,
    } = this.state;
    // this.setState({ background: color.hex });
    var isColor = this.findIndexInObject(timeseriesId, timeseriesColor);
    if (isColor) {
      timeseriesColor[timeseriesId] = color.hex;
      this.setState({ timeseriesColor });
    } else {
      Object.assign(timeseriesColor, { [timeseriesId]: color.hex });
    }
    // var isColor = this.findIndexInObject(timeseriesId, timeseriesObjectList);

    for (var i in timeseriesObjectList) {
      if (timeseriesObjectList[i].id === timeseriesId) {
        timeseriesObjectList[i].timeseriesColor[timeseriesId] = color.hex;
        timeseriesObjectList[i].color = color.hex;
        this.setState({ timeseriesObjectList, background });
        break; //Stop this loop, we found it!
      }
    }
  };

  handleMinMax(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    const newState = { ...this.state, [itemName]: itemValue };
    this.setState(newState);
    const { timeseriesId, minMaxMap, min, max } = newState;

    if (min !== null && max !== null && min < max) {
      var isIndex = this.findIndexInObject(timeseriesId, minMaxMap);
      if (isIndex) {
        minMaxMap[timeseriesId].min = min;
        minMaxMap[timeseriesId].max = max;

        this.setState({ minMaxMap });
      } else {
        Object.assign(minMaxMap, {
          [timeseriesId]: [min, max],
        });
      }
    }
  }
  handlePositionChange = (value) => {
    const { timeseriesObjectList, timeseriesId } = this.state;
    for (var i in timeseriesObjectList) {
      if (timeseriesObjectList[i].id === timeseriesId) {
        timeseriesObjectList[i].yAxisPlacement = `${value.target.value}`;
        break; //Stop this loop, we found it!
      }
    }
    this.setState({ timeseriesObjectList });
  };
  handleInfographicName(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState({ [itemName]: itemValue });
  }

  handleUnitChange = (cmbUnit) => {
    const { timeseriesObjectList, timeseriesId } = this.state;

    for (var i in timeseriesObjectList) {
      if (timeseriesObjectList[i].id === timeseriesId) {
        timeseriesObjectList[i].units[timeseriesId] = cmbUnit;
        break; //Stop this loop, we found it!
      }
    }
    this.setState({ timeseriesObjectList, timeseriesUnit: cmbUnit });
  };
  zoomable(timeseriesObjectList) {
    return timeseriesObjectList.reduce(
      (prev, current) => prev && !this.state.minMaxMap[current.id],
      true
    );
  }
  render() {
    const { infoRightBar, rootId, editChart } = this.props;
    const {
      timeseriesObjectList,
      timeseriesIds,
      switched,
      timeseriesColor,
      error,
      min,
      max,
    } = this.state;
    var heightRightBar = infoRightBar - 114;
    console.log(this.state.timeseriesObjectList);
    return (
      <div style={{ height: heightRightBar + "px" }}>
        <div className="timeseries-header">
          <section>
            <AiOutlineStock
              style={{
                backgroundColor: " rgb(245, 245, 245)",
                fontSize: "24px",
                marginTop: "17px",
              }}
            />
            <div className="asset-name">
              <div className="sensor-name">
                <div
                  className="text-font editable-text-disabled"
                  data-testid="editable-text-disabled"
                >
                  <Input
                    style={{ width: "240px" }}
                    placeholder="New timeseries name"
                    id="infographic"
                    required
                    name="timeseriesName"
                    value={this.state.timeseriesName}
                    onChange={this.handleInfographicName}
                  />
                </div>
              </div>
              <div style={{ paddingTop: "10px" }}>
                <AssetBreadcrumb
                  onBreadcrumbClick={this.onBreadcrumbClick}
                  assetId={rootId}
                  maxLength={10}
                />
              </div>
            </div>
          </section>

          <section>
            <div style={{ marginRight: "24px" }}>
              {/* <ColorPicker  /> */}
              <DateRange range={this.range} />
              {this.state.editable === false ? (
                <Button
                  onClick={this.handleSubmit}
                  type="primary"
                  style={{ marginLeft: "24px" }}
                >
                  <SaveOutlined /> Save
                </Button>
              ) : (
                <Button
                  onClick={this.handleUpdate}
                  type="primary"
                  style={{ marginLeft: "24px" }}
                >
                  <SaveOutlined /> update
                </Button>
              )}
            </div>
          </section>
        </div>
        <div className="info-content-wrapper">
          <div
            className="info-content-left timeseries-chart"
            style={{ overflow: "hidden" }}
          >
            <UnitList units={timeseriesObjectList && timeseriesObjectList} />
            <TimeseriesChart
              ruler={ruler}
              timeseriesIds={timeseriesIds && timeseriesIds}
              startTime={this.state.startDate}
              endTime={this.state.endDate}
              zoomable={this.zoomable(timeseriesObjectList)}
              contextChart={true}
              height={heightRightBar - 30}
              yAxisPlacement={
                timeseriesObjectList &&
                timeseriesObjectList.map((x) => x.yAxisPlacement)
              }
              timeseriesColors={timeseriesColor && timeseriesColor}
              ySubDomains={this.state.minMaxMap}
            />
          </div>
          <div className="info-content-right divider">
            <div
              className="info-right-wrapper"
              style={{ width: "100%", height: heightRightBar + "px" }}
            >
              <Tabs defaultActiveKey="5">
                <TabPane
                  tab={
                    <span>
                      <TagsOutlined />
                      Add tag
                    </span>
                  }
                  key="1"
                >
                  <div className="grid-wrapper">
                    <TimeseriesSearch
                      selectedTimeseries={
                        editChart === undefined
                          ? this.state.timeseriesIds
                          : editChart.timeseriesIds
                      }
                      filterRule={this.filterRule}
                      rootAsset={this.props.rootId}
                      onTimeserieSelectionChange={
                        this.onTimeserieSelectionChange
                      }
                      rootAssetSelect={true}
                      styles={customStyles}
                      allowStrings={false}
                      hideSelected={true}
                    />
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <EditOutlined />
                      Edit tag
                    </span>
                  }
                  key="3"
                >
                  <div className="grid-wrapper" style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "250px",
                        paddingLeft: "14px",
                        height: `${heightRightBar - 55}px`,
                        overflow: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {timeseriesObjectList &&
                        timeseriesObjectList.map((item) => {
                          return (
                            <span key={item.id}>
                              {
                                <TimeseriesPreview
                                  className="ejbipv"
                                  color={item.color}
                                  timeseriesId={item.id}
                                  dropdown={{
                                    options: menuConfig,
                                    onClick: (e) => this.onMenuClick(e, item),
                                  }}
                                  onToggleVisibility={this.onToggleVisibility}
                                />
                              }
                            </span>
                          );
                        })}
                    </div>
                    <div
                      style={{
                        width: "250px",
                        height: `${heightRightBar}px`,
                        overflow: "auto",
                        background: "rgb(0, 37, 58)",
                        position: "relative",
                      }}
                    >
                      <div className="edit_tag">
                        {this.state.timeseriesId !== null ? (
                          <>
                            <span className="tag_id">
                              ID: {this.state.timeseriesId}{" "}
                            </span>
                            <h2 className="tag_color">Color</h2>
                            <SketchPicker
                              color={this.state.background}
                              onChangeComplete={this.handleChangeComplete}
                            />
                            <div className="tag_Yaxis">
                              <Form
                                initialValues={{
                                  layout: "vertical",
                                }}
                              >
                                <h2>Y axis</h2>
                                <Form.Item>
                                  <h2 style={{ fontSize: "12px" }}>
                                    FIXED AXIS
                                  </h2>
                                  <Switch
                                    defaultChecked={false}
                                    onChange={this.switchYaxies}
                                  />
                                  {switched && (
                                    <Row gutter={8}>
                                      <Col span={10}>
                                        <h2 style={{ fontSize: "12px" }}>
                                          Min
                                        </h2>
                                        <Input
                                          step="any"
                                          required
                                          autofocus
                                          type="number"
                                          onChange={this.handleMinMax}
                                          id="min"
                                          value={min}
                                          name="min"
                                          style={{
                                            width: 100,
                                            textAlign: "center",
                                          }}
                                          placeholder="Min"
                                        />
                                      </Col>
                                      <Col span={10}>
                                        <h2 style={{ fontSize: "12px" }}>
                                          Max
                                        </h2>
                                        <Input
                                          step="any"
                                          required
                                          type="number"
                                          onChange={this.handleMinMax}
                                          id="max"
                                          value={max}
                                          name="max"
                                          style={{
                                            width: 100,
                                            textAlign: "center",
                                          }}
                                          placeholder="Max"
                                        />
                                      </Col>
                                      {error && (
                                        <Col>
                                          <h2
                                            style={{
                                              fontSize: "2px",
                                              color: "red",
                                            }}
                                          >
                                            {error}
                                          </h2>{" "}
                                        </Col>
                                      )}
                                    </Row>
                                  )}
                                </Form.Item>
                              </Form>
                            </div>
                            <div className="tag_Yaxis">
                              <Form
                                initialValues={{
                                  layout: "vertical",
                                }}
                              >
                                <h2>Y AXIS UNIT</h2>
                                <Form.Item>
                                  <h2
                                    style={{
                                      fontSize: "12px",
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    UNIT: <b>{this.state.timeseriesUnit}</b>
                                  </h2>
                                  <h2 style={{ fontSize: "12px" }}>
                                    Change unit of Y axis
                                  </h2>
                                  <Select
                                    defaultValue={this.state.timeseriesUnit}
                                    style={{ width: 120 }}
                                    onChange={this.handleUnitChange}
                                  >
                                    {UNIT &&
                                      UNIT.map((item) => {
                                        return (
                                          <Option key={item} value={item}>
                                            {item}
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                </Form.Item>
                              </Form>
                            </div>
                            {/*                    <div className = "tag_Yaxis">
                        <Form
                          initialValues={{
                            layout: "vertical",
                          }}
                        >
                        <h2>Y AXIS PLACEMENT</h2>
                        <Form.Item>
                        <h2 style={{fontSize: '12px'}}>Change position of Y axis</h2>
                        <Radio.Group onChange={this.handlePositionChange}>
                          <Radio.Button value="LEFT">Left</Radio.Button>
                          <Radio.Button value="RIGHT">Right</Radio.Button>
                        </Radio.Group>
                        </Form.Item>
                        <Form.Item>
                        </Form.Item>
                      </Form> 
                      </div> */}
                          </>
                        ) : (
                          <div className="tag_Yaxis">
                            <h2
                              style={{
                                fontSize: "16px",
                                textTransform: "lowercase",
                              }}
                            >
                              Select a tag to the right
                            </h2>{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
