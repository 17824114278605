import React, { Component } from "react";
import {
  Button,
  Modal,
  Menu,
  Dropdown,
  Radio,
  Input,
  Tag,
  notification,
  message,
  Skeleton,
} from "antd";
import moment from "moment";
import {
  TimeseriesChart,
  SensorOverlay,
  TimeseriesDataExport,
} from "@cognite/gearbox";
import DateRange from "./DateRange";

import {
  DotChartOutlined,
  LineChartOutlined,
  PictureOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  PlusOutlined,
  CloudDownloadOutlined,
  FilterOutlined,
} from "@ant-design/icons";

import { Link, navigate } from "@reach/router";
import firebase from "./../Firebase";
import UnitList from "./timeseriesChart/UnitList";
//import { AxisDisplayMode } from '@cognite/griff-react';
/* const seriesWithCustomYdomain = [
  {
    id: 2941749563344228,
    color: 'red',
    yAxisDisplayMode: AxisDisplayMode.ALL,
    hidden: false,
    ySubDomain: [-150, 150],
  },
  {
    id: 129125333097632,
    color: 'green',
    yAxisDisplayMode: AxisDisplayMode.ALL,
    hidden: false,
    ySubDomain: [-150, 150],
  },
  {
    id: 2113333851475967,
    color: 'yellow',
    yAxisDisplayMode: AxisDisplayMode.ALL,
    hidden: false,
    ySubDomain: [-150, 150],
  },
  {
    id: 103338248149450,
    color: 'blue',
    yAxisDisplayMode: AxisDisplayMode.ALL,
    ySubDomain: [-150, 150],
  },
]; */
const RadioGroup = Radio.Group;

const TimeseriesChartExportWrapper = (props) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  return (
    <>
      <Button style={{ marginLeft: "24px" }} onClick={onOpen}>
        <CloudDownloadOutlined />
      </Button>
      <TimeseriesDataExport visible={open} hideModal={onClose} {...props} />
    </>
  );
};
export default class Board extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      visible: false,
      datePicker: false,
      timeseriesList: null,
      boardList: null,
      display: null,
      datePickerName: "LAST MONTH",
      startDate: null,
      endDate: null,
      infographicName: "",
      imageUrl: null,
      infographicObj: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleInfographicName = this.handleInfographicName.bind(this);
  }
  handleInfographicName(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState({ [itemName]: itemValue });
  }

  componentDidMount() {
    /*
    1 - scatterplot
    2 - timeseries
    3- infographic
    */

    const ref = firebase.database().ref(`board/${this.props.rootId}`);

    ref.on("value", (snapshot) => {
      let boards = snapshot.val();
      let boardsList = [];
      for (let item in boards) {
        let isAdmin = this.props.userID === boards[item].userID;
        if (isAdmin) {
          if (boards[item].type === 3) {
            boardsList.push({
              type: boards[item].type,
              itemID: item,
              name: boards[item].name,
              userID: boards[item].userID,
              user: boards[item].user,
              alartColor: boards[item].alartColor,
              colorMap: boards[item].colorMap,
              minMaxMap: boards[item].minMaxMap,
              SensorPosition: boards[item].SensorPosition,
              imageUrl: boards[item].imageUrl,
              tags: boards[item].tags,
              public: boards[item].public,
              created: boards[item].created,
              timeseriesIds: boards[item].timeseriesIds,
              modified: boards[item].modified,
            });
          } else if (boards[item].type === 2) {
            boardsList.push({
              type: boards[item].type,
              itemID: item,
              name: boards[item].name,
              userID: boards[item].userID,
              user: boards[item].user,
              tags: boards[item].tags,
              startDate: boards[item].startDate,
              endDate: boards[item].endDate,
              created: boards[item].created,
              timeseriesIds: boards[item].timeseriesIds,
              zoom: boards[item].zoom,
              modified: boards[item].modified,
              timeseriesColor: boards[item].timeseriesColor,
              minMaxMap: boards[item].minMaxMap,
            });
          } else if (boards[item].type === 1) {
            // scatterplot
          }
        }
      }
      let sortedByLatest = boardsList.sort((a, b) =>
        a.created < b.created ? 1 : -1
      );
      this.setState({
        boardList: sortedByLatest,
        timeseriesList: sortedByLatest,
        display: sortedByLatest,
        loading: false,
      });
    });
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  showDatePicker = () => {
    this.setState({
      datePicker: !this.state.datePicker,
    });
  };
  expand = () => {
    if (this.state.datePicker === true) {
      this.setState({
        datePicker: false,
      });
    }
  };
  handleClick = (timeserieId) => {};
  handleLinkClick = async (timeserieId, datapoint) => {
    const timeseriesObj = await this.props.client.timeseries.retrieve([
      { id: timeserieId },
    ]);
    this.props.getTimeseriesObj(timeseriesObj[0], datapoint);
    navigate(`/timeseries/${timeserieId}`);
  };

  handleSave(item) {
    const { startDate, endDate, infographicName } = this.state;
    var start = startDate === null ? item.startDate : startDate;
    var end = endDate === null ? item.endDate : endDate;
    var name = infographicName === "" ? item.infographicName : infographicName;
    if (infographicName === "") {
      return;
    }
    if (item.type === 3) {
      firebase
        .database()
        .ref(`board/${this.props.rootId}/${item.itemID}`)
        .set(
          {
            name: name,
            type: item.type,
            user: item.user,
            tags: item.tags,
            userID: item.userID,
            SensorPosition: item.SensorPosition,
            imageUrl: item.imageUrl,
            public: item.public,
            timeseriesIds: item.timeseriesIds,
            created: item.created,
            modified: moment().format("MM DD YYYY, HH:mm:ss "),
          },
          (error) => {
            if (error) {
              // The write failed...
              message.info("Failed to delete item!");
            } else {
              // Data saved successfully!
              notification.open({
                message: "Successfully updated item",
                description: `Infographic with ID: ${item.itemID} was updated successfully.`,
                duration: 4,
              });
            }
          }
        );
    } else if (item.type === 2) {
      firebase
        .database()
        .ref(`board/${this.props.rootId}/${item.itemID}`)
        .set(
          {
            name: name,
            type: item.type,
            userID: item.userID,
            user: item.user,
            tags: item.tags,
            timeseriesIds: item.timeseriesIds,
            startDate: start,
            endDate: end,
            created: item.created,
            modified: moment().format("MM DD YYYY, HH:mm:ss "),
          },
          (error) => {
            if (error) {
              // The write failed...
              message.info("Failed to delete item!");
            } else {
              // Data saved successfully!
              notification.open({
                message: "Successfully updated item",
                description: `Timeseries with ID: ${item.itemID} was updated successfully.`,
                duration: 4,
              });
            }
          }
        );
    }
  }

  handleDelete = (item) => {
    const ref = firebase
      .database()
      .ref(`board/${this.props.rootId}/${item.itemID}`);
    ref.remove(function (error) {
      if (error) {
        // The write failed...
        message.info("Failed to delete item!");
      } else {
        // Data saved successfully!
        notification.open({
          message: "Successfully deleted item",
          description: `Infographic board with ID: ${item.itemID} was deleted successfully.`,
          duration: 5,
        });
      }
    });
  };

  displayDate = (e) => {
    const { boardList } = this.state;
    let value = e.target.value;
    let tmpList = [];

    if (value === 3) {
      for (let item in boardList) {
        if (boardList[item].type === value) {
          tmpList.push({
            type: boardList[item].type,
            itemID: item,
            name: boardList[item].name,
            userID: boardList[item].userID,
            user: boardList[item].user,
            alartColor: boardList[item].alartColor,
            colorMap: boardList[item].colorMap,
            minMaxMap: boardList[item].minMaxMap,
            SensorPosition: boardList[item].SensorPosition,
            imageUrl: boardList[item].imageUrl,
            tags: boardList[item].tags,
            public: boardList[item].public,
            created: boardList[item].created,
            timeseriesIds: boardList[item].timeseriesIds,
          });
        }
      }
      this.setState({ timeseriesList: tmpList });
    } else if (value === 2) {
      for (let item in boardList) {
        if (boardList[item].type === value) {
          tmpList.push({
            type: boardList[item].type,
            itemID: item,
            name: boardList[item].name,
            userID: boardList[item].userID,
            zoom: boardList[item].minMaxMap === undefined ? true : false,
            user: boardList[item].user,
            tags: boardList[item].tags,
            startDate: boardList[item].startDate,
            endDate: boardList[item].endDate,
            created: boardList[item].created,
            timeseriesIds: boardList[item].timeseriesIds,
            modified: boardList[item].modified,
            timeseriesColor: boardList[item].timeseriesColor,
            minMaxMap: boardList[item].minMaxMap,
          });
        }
      }
      this.setState({ timeseriesList: tmpList });
    } else {
      this.setState({ timeseriesList: boardList });
    }
  };

  addTag = (infographicObj) => {
    this.props.getInfographicObj(infographicObj);
    navigate(`/infographics/${infographicObj.itemID}`);
  };
  range = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate,
    });
  };

  handleEditChart = (item) => {
    this.props.editChart(item);
    navigate(`/timeseries/${item.itemID}`);
  };

  render() {
    const { visible, startDate, endDate } = this.state;

    const boardsList =
      this.state.timeseriesList &&
      this.state.timeseriesList.map((item) => {
        if (item.type === 3) {
          return (
            <div className="board" key={item.itemID}>
              <div className="board-header">
                <div style={{ display: "flex" }}>
                  <h4>{item.name}</h4>
                  <Dropdown
                    overlay={
                      <Menu>
                        <div className="date-picker">
                          <div className="date-picker-header">
                            <Input
                              placeholder={`${item.name}`}
                              id="infographicName"
                              required
                              name="infographicName"
                              value={this.state.infographicName}
                              onChange={this.handleInfographicName}
                            />
                            <Button
                              onClick={() => this.handleSave(item)}
                              style={{ marginLeft: "24px" }}
                            >
                              <SaveOutlined />
                              Update name
                            </Button>
                            <Button
                              onClick={() => this.handleDelete(item)}
                              style={{
                                marginLeft: "24px",
                                color: "white",
                                backgroundColor: "#cd113e",
                                borderColor: " #fa4d70",
                              }}
                            >
                              <DeleteOutlined />
                              Delete
                            </Button>
                          </div>
                        </div>
                      </Menu>
                    }
                  >
                    <Button style={{ marginLeft: "24px" }}>
                      <EditOutlined />
                      Edit
                    </Button>
                  </Dropdown>
                </div>
              </div>
              <div style={{ height: "500px" }}>
                {item.imageUrl !== null ? (
                  <SensorOverlay
                    onSensorPositionChange={this.props.onSensorPositionChange}
                    defaultPositionMap={item.SensorPosition}
                    timeseriesIds={item.timeseriesIds}
                    alertColor={item.alartColor}
                    colorMap={item.colorMap}
                    minMaxMap={item.minMaxMap}
                    onClick={this.handleClick}
                    onLinkClick={this.handleLinkClick}
                    linksMap={true}
                  >
                    <img
                      style={{ width: "100%", height: "500px" }}
                      src={`${item.imageUrl}`}
                      alt="New infographics"
                    />
                  </SensorOverlay>
                ) : (
                  <img
                    style={{ width: "100%", height: "500px" }}
                    src="../../img/infographicImg.jpg"
                    alt="New infographics"
                  />
                )}
              </div>
              <div style={{ margin: "5px 0px 0px 0px" }}>
                {item.tags.map((tag) => {
                  return (
                    <span>
                      {<Tag key={tag.externalId}>{tag.externalId}</Tag>}{" "}
                    </span>
                  );
                })}
                <Tag
                  className="site-tag-plus"
                  onClick={(e) => this.addTag(item)}
                >
                  <PlusOutlined /> New Tag
                </Tag>
              </div>
            </div>
          );
        } else if (item.type === 2) {
          var start = startDate === null ? item.startDate : startDate;
          var end = endDate === null ? item.endDate : endDate;
          return (
            <div className="board" key={item.itemID}>
              <div className="board-header">
                <div style={{ display: "flex" }}>
                  <h4>{item.name}</h4>
                </div>
                <div style={{ margin: "5px 0px 0px 0px" }}>
                  <span style={{ marginLeft: "24px" }}>
                    Start date: {moment(start).format("MMM DD, YYYY HH:mm:ss")}{" "}
                  </span>
                  <span style={{ marginLeft: "24px" }}>
                    End date: {moment(end).format("MMM DD, YYYY HH:mm:ss")}{" "}
                  </span>
                </div>
                <div>
                  <DateRange range={this.range} />
                  <Button
                    onClick={() => this.handleEditChart(item)}
                    style={{ marginLeft: "15px", cursor: "pointer" }}
                  >
                    <EditOutlined />
                    Edit
                  </Button>
                  <Button
                    style={{ marginLeft: "15px", cursor: "pointer" }}
                    onClick={() => this.handleDelete(item)}
                  >
                    <DeleteOutlined />
                    Delete
                  </Button>
                  <TimeseriesChartExportWrapper
                    timeseriesIds={item.timeseriesIds}
                    granularity={"2m"}
                    defaultTimeRange={[start, end]}
                  />
                </div>
              </div>
              <UnitList units={item.tags} />
              <TimeseriesChart
                timeseriesIds={item.timeseriesIds}
                startTime={`${startDate === null ? item.startDate : startDate}`}
                endTime={`${endDate === null ? item.endDate : endDate}`}
                //zoomable={{ zoomAxes: { y: false } }}
                zoomable={{ yAxis: false, xAxis: true }}
                contextChart={true}
                height={450}
                //zoomAxes={true}
                yAxisPlacement={item.tags.map((x) => x.yAxisPlacement)}
                timeseriesColors={item.timeseriesColor}
                ySubDomains={item.minMaxMap}
              />
              <div style={{ margin: "8px 0px" }}>
                {item.tags.map((tag) => {
                  return (
                    <span key={tag.id}>
                      {<Tag color={tag.color}>{tag.externalId}</Tag>}{" "}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      });
    return (
      <>
        <header className="info-header" style={{ backgroundColor: "#00253a" }}>
          <div className="info-header-name-button">
            <div style={{ display: "flex" }}>
              <h2 className="dashboard-header">Visualization Board</h2>
              <Dropdown
                overlay={
                  <Menu>
                    <div className="date-picker">
                      <div
                        className="date-picker-header"
                        style={{ display: "block" }}
                      >
                        <h5 style={{ fontSize: "14px" }}>Filter By:</h5>
                        <RadioGroup
                          style={{ marginRight: 8 }}
                          defaultValue={0}
                          onChange={this.displayDate}
                        >
                          <Radio value={3}>Infographic</Radio>
                          <Radio value={2}>Timeseries</Radio>
                          <Radio value={0}>both (default)</Radio>
                        </RadioGroup>
                      </div>
                    </div>
                  </Menu>
                }
              >
                <Button className="filter-board" type="default">
                  <FilterOutlined />
                </Button>
              </Dropdown>
            </div>
            <Button type="primary" shape="circle" onClick={this.showModal}>
              <PlusOutlined />
            </Button>
            <Modal
              width={850}
              centered={false}
              visible={visible}
              title="Select Visualization Type"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={null}
            >
              <div className="visualization-wrapper">
                <div className="visualization-buttons">
                  <Link to="/timeseries/new">
                    <button className="button-content" type="button">
                      <LineChartOutlined
                        style={{
                          fontSize: "24px",
                          marginRight: "25px",
                          color: "#4fb8bb",
                        }}
                      />
                      <div>
                        <h4>Line chart</h4>
                        <p>Compare time series along a specified time range</p>
                      </div>
                    </button>
                  </Link>
                  <button className="button-content" type="button">
                    <DotChartOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "25px",
                        color: "#4fb8bb",
                      }}
                    />
                    <div>
                      <h4>Scatter plot</h4>
                      <p>Correlate time series pairs along a time range</p>
                    </div>
                  </button>
                  <Link to="/infographics/new">
                    <button className="button-content" type="button">
                      <PictureOutlined
                        style={{
                          fontSize: "24px",
                          marginRight: "25px",
                          color: "#4fb8bb",
                        }}
                      />
                      <div>
                        <h4>Infographic</h4>
                        <p>
                          Overlay current time series values over a custom image
                        </p>
                      </div>
                    </button>
                  </Link>
                </div>
              </div>
            </Modal>
          </div>
        </header>
        {this.state.loading === true ? (
          <div className="board">
            <div className="board-header">
              <Skeleton />
            </div>
          </div>
        ) : null}
        {boardsList}
      </>
    );
  }
}
