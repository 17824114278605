import React, { Component } from "react";
import { Input, Icon, Upload, message, notification, Button } from "antd";
import { TimeseriesSearch, SensorOverlay } from "@cognite/gearbox";
import { SketchPicker, BlockPicker } from "react-color";
import { getExternalIdsWithSameTimeseriesIds } from "../../ClientHelperFun";

import moment from "moment";
import firebase from "../../Firebase";

import { SmileOutlined } from "@ant-design/icons";

const customStyles = {
  buttonRow: { marginTop: "15px" },
  selectAllButton: { display: "none" },
  selectNoneButton: {
    backgroundColor: "#5f9ea0",
    display: "none",
  },
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

export default class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "large",
      infographicName: "",
      visible: false,

      min: null,
      max: null,
      colorMap: {},
      minMaxMap: {},
      background: "",
      settingId: null,
      alartColor: "",
      isSettings: false,

      isShrink: false,
      isAddTags: false,
      imageWidth: "100%",
      isImage: false,
      isSave: false,
      isChart: false,
      toggleMenu: false,
      isActive: false,
      timeseriesIds: [],
      loading: false,
      imageUrl: null,
      externalIds: [],
      sensorList: [],
      defaultPositionMap: [],
    };
    this.addInfographicValue = this.addInfographicValue.bind(this);
    this.handleInfographicName = this.handleInfographicName.bind(this);
    this.handleMinMax = this.handleMinMax.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };
  showDrawer = () => {
    this.setState({
      visible: true,
      imageWidth: "70%",
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
      imageWidth: "91%",
    });
  };
  handleInfographicName(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState({ [itemName]: itemValue });
  }
  handleMinMax(e) {
    const { settingId, minMaxMap, min, max, background, colorMap } = this.state;

    if (min !== null && max !== null) {
      var isIndex = this.findIndexInObject(settingId, minMaxMap);
      if (isIndex) {
        minMaxMap[settingId].min = min;
        minMaxMap[settingId].max = max;

        this.setState({ minMaxMap });
      } else {
        Object.assign(minMaxMap, {
          [settingId]: {
            min: min,
            max: max,
          },
        });
      }
    }
    if (background !== "") {
      var isColor = this.findIndexInObject(settingId, colorMap);
      if (isColor) {
        colorMap[settingId] = background;
        this.setState({ colorMap });
      } else {
        Object.assign(colorMap, { [settingId]: this.state.background });
      }
    }
    this.setState({ max: null, min: null, background: "" });
  }
  closeColorPalette = () => {
    this.setState({
      max: null,
      min: null,
      background: "",
      isSettings: false,
    });
  };
  handleChangeComplete = (color) => {
    const { settingId, colorMap, background } = this.state;
    this.setState({ background: color.hex });
    if (background !== "") {
      var isColor = this.findIndexInObject(settingId, colorMap);
      if (isColor) {
        colorMap[settingId] = background;
        this.setState({ colorMap });
      } else {
        Object.assign(colorMap, { [settingId]: this.state.background });
      }
    }
  };
  onSettings = (id) => {
    this.setState({ isSettings: true, isActive: false, settingId: id });
  };
  alartColor = (color, event) => {
    this.setState({ alartColor: color.hex });
  };
  handleSubmit(e) {
    e.preventDefault();

    if (this.state.infographicName === "") {
      notification.error({
        message: "Error",
        description: "Edit the title of the infographic before saving.",
        duration: 5,
      });
      return;
    }
    if (this.state.timeseriesIds.length === 0) {
      notification.error({
        message: "Error",
        description: "You need to add some tags to save the infographic.",
        duration: 5,
      });
      return;
    }
    const tmpObj = {
      type: 3,
      name: this.state.infographicName,
      userID: firebase.auth().currentUser.uid,
      alartColor: this.state.alartColor,
      colorMap: this.state.colorMap,
      minMaxMap: this.state.minMaxMap,
      user: firebase.auth().currentUser.uid,
      SensorPosition: this.state.defaultPositionMap,
      imageUrl: this.state.imageUrl,
      tags: this.state.externalIds,
      timeseriesIds: this.state.timeseriesIds,
      public: false,
      created: moment().format("MMM Do YYYY, HH:MM:ss"),
      modified: moment().format("MMM Do YYYY, HH:MM:ss"),
    };
    const ref = firebase.database().ref(`board/${this.props.rootId}`);
    ref.push(tmpObj, function (error) {
      if (error) {
        // The write failed...
        message.info("Error has occured during saving process!");
      } else {
        // Data saved successfully!
        notification.open({
          message: "Data has been saved succesfully",
          description: "",
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          duration: 3,
        });
      }
    });
  }
  toggleMenu = () => {
    const toggle = this.state.toggleMenu;
    if (toggle) {
      this.setState({ imageWidth: "70%" });
    } else {
      this.setState({ imageWidth: "91%" });
    }
    this.setState({
      toggleMenu: !toggle,
      isActive: false,
    });
  };
  onTimeserieSelectionChange = async (timeseriesIds, externalId) => {
    let ext = externalId.externalId;
    let id = externalId.id;
    this.state.externalIds.push({ id: id, externalId: ext });

    var tags = getExternalIdsWithSameTimeseriesIds(
      timeseriesIds,
      this.state.externalIds
    );

    this.setState({ timeseriesIds, externalIds: tags });
  };

  filterRule = (timeseries) => !timeseries.isString;

  findIndexInObject = (id, object) => {
    for (var index in object) {
      if (object[index] === id) {
        return true;
      }
    }
    return false;
  };
  onSensorPositionChange = (id, position) => {
    const { defaultPositionMap } = this.state;

    var isIndex = this.findIndexInObject(id, defaultPositionMap);
    if (isIndex) {
      defaultPositionMap[id].color = position.color;
      defaultPositionMap[id].left = position.left;
      defaultPositionMap[id].top = position.top;
      defaultPositionMap[id].pointer.left = position.pointer.left;
      defaultPositionMap[id].pointer.top = position.pointer.top;

      this.setState({ defaultPositionMap });
    } else {
      Object.assign(this.state.defaultPositionMap, {
        [id]: {
          id: id,
          color: position.color,
          left: position.left,
          top: position.top,
          pointer: {
            left: position.pointer.left,
            top: position.pointer.top,
          },
        },
      });
    }
  };
  addInfographicValue = (e) => {
    var selected = e.currentTarget.id;

    switch (selected) {
      case "1":
        this.setState({
          isShrink: true,
          isAddTags: false,
          isImage: false,
          isSave: false,
          isChart: false,
          isSettings: false,
          isActive: true,
          imageWidth: "70%",
        });
        break;
      case "2":
        this.setState({
          isShrink: false,
          isAddTags: true,
          isImage: false,
          isSave: false,
          isChart: false,
          isSettings: false,
          isActive: true,
          imageWidth: "70%",
        });
        break;
      case "3":
        this.setState({
          isShrink: false,
          isAddTags: false,
          isImage: true,
          isSave: false,
          isChart: false,
          isActive: true,
          isSettings: false,
          imageWidth: "70%",
        });
        break;
      case "4":
        this.setState({
          isShrink: false,
          isAddTags: false,
          isImage: false,
          isSave: true,
          imageWidth: "70%",
          isChart: false,
          isSettings: false,
          isActive: true,
        });
        break;
      case "5":
        this.setState({
          isShrink: false,
          isAddTags: false,
          isImage: false,
          isSave: false,
          isChart: true,
          isActive: true,
          imageWidth: "70%",
          isSettings: false,
        });
        break;
      default:
        console.log("unknown selection input");
    }
  };
  render() {
    const {
      isShrink,
      isAddTags,
      isImage,
      isSave,
      isChart,
      isActive,
      imageUrl,
      isSettings,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <>
        <div className="infographic-view-container">
          <div className="new-header-nav">
            <div className="input-name">
              <Input
                style={{ backgroundColor: "rgb(67, 75, 87)", color: "white" }}
                placeholder="New Infographic name"
                id="infographic"
                required
                name="infographicName"
                value={this.state.infographicName}
                onChange={this.handleInfographicName}
              />
            </div>
            <div className="nav-wrapper">
              <div
                id={1}
                onClick={this.addInfographicValue}
                className={`${
                  isShrink === true ? "expand-tags-active" : "expand-tags"
                }`}
              >
                <Icon type="eye-invisible" />
                <div>Shrink</div>
              </div>
              <div
                id={2}
                onClick={this.addInfographicValue}
                className={`${
                  isAddTags === true ? "expand-tags-active" : "expand-tags"
                }`}
              >
                <Icon type="tags" />
                <span>Add tag</span>
              </div>
              <div
                id={3}
                onClick={this.addInfographicValue}
                className={`${
                  isImage === true ? "expand-tags-active" : "expand-tags"
                }`}
              >
                <Icon type="picture" />
                <div>Image</div>
              </div>
              <div
                id={4}
                onClick={this.handleSubmit}
                className={`${
                  isSave === true ? "expand-tags-active" : "expand-tags"
                }`}
              >
                <Icon type="save" />
                <div>Save</div>
              </div>
              <div
                id={5}
                onClick={this.addInfographicValue}
                className={`${
                  isChart === true ? "expand-tags-active" : "expand-tags"
                }`}
              >
                <Icon type="line-chart" />
                <div>Chart</div>
              </div>
              {isActive && (
                <div onClick={this.toggleMenu}>
                  {this.state.toggleMenu === false ? (
                    <Icon type="menu-unfold" />
                  ) : (
                    <Icon type="menu-fold" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="new-content">
            {isActive && (
              <div className="nav-content">
                <div className="nav-content-margins">
                  {isAddTags && (
                    <TimeseriesSearch
                      filterRule={this.filterRule}
                      allowStrings={false}
                      rootAsset={this.props.rootId}
                      onTimeserieSelectionChange={
                        this.onTimeserieSelectionChange
                      }
                      rootAssetSelect={true}
                      styles={customStyles}
                    />
                  )}
                </div>
                {isImage && (
                  <div
                    className="nav-content-margins"
                    style={{ height: "200px", margin: "0 auto" }}
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={this.handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                )}
              </div>
            )}
            {isSettings && (
              <div className="side-color">
                <div className="side-color-margins">
                  <h2 style={{ color: "white", fontSize: "16px" }}>
                    Select a color
                  </h2>
                  <SketchPicker
                    color={this.state.background}
                    onChangeComplete={this.handleChangeComplete}
                  />
                  <h2
                    style={{
                      color: "white",
                      fontSize: "16px",
                      marginTop: "24px",
                    }}
                  >
                    Indicator range
                  </h2>
                  <Input.Group compact>
                    <Input
                      onChange={this.handleInfographicName}
                      id="min"
                      value={this.state.min}
                      name="min"
                      style={{ width: 100, textAlign: "center" }}
                      placeholder="Minimum"
                    />
                    <Input
                      className="site-input-split"
                      style={{
                        width: 30,
                        borderLeft: 0,
                        borderRight: 0,
                        pointerEvents: "none",
                      }}
                      placeholder="~"
                      disabled
                    />
                    <Input
                      id="max"
                      value={this.state.max}
                      name="max"
                      onChange={this.handleInfographicName}
                      className="site-input-right"
                      style={{
                        width: 100,
                        textAlign: "center",
                      }}
                      placeholder="Maximum"
                    />
                  </Input.Group>
                  <h2
                    style={{
                      color: "white",
                      fontSize: "16px",
                      marginTop: "24px",
                      marginBottom: "10px",
                    }}
                  >
                    Select a alart color
                  </h2>
                  <BlockPicker onChangeComplete={this.alartColor} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "24px",
                    }}
                  >
                    <Button onClick={this.handleMinMax} type="primary">
                      Save settings
                    </Button>
                    <Button onClick={this.closeColorPalette}>Cancel</Button>
                  </div>
                </div>
              </div>
            )}

            <div
              style={{ position: "relative", width: this.state.imageWidth }}
              className="infographic-view"
            >
              {imageUrl !== null ? (
                <SensorOverlay
                  timeseriesIds={this.state.timeseriesIds}
                  onSettingsClick={this.onSettings}
                  alertColor={this.state.alartColor}
                  colorMap={this.state.colorMap}
                  minMaxMap={this.state.minMaxMap}
                  onSensorPositionChange={this.onSensorPositionChange}
                  defaultPositionMap={this.state.defaultPositionMap}
                  onClick={this.handleClick}
                  onLinkClick={this.handleLinkClick}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={`${imageUrl}`}
                    alt="New infographics"
                  />
                </SensorOverlay>
              ) : (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src="../../img/infographicImg.jpg"
                  alt="New infographics"
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
