import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBAUBC5N4lElqQC0ly1WuKoXIPrl0mx2kM",
  authDomain: "eep-frontend-v1.firebaseapp.com",
  databaseURL: "https://eep-frontend-v1.firebaseio.com",
  projectId: "eep-frontend-v1",
  storageBucket: "eep-frontend-v1.appspot.com",
  messagingSenderId: "455768494897",
  appId: "1:455768494897:web:c444372623414a4bd61610",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export default firebase;
