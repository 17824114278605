import React, { Component } from "react";
import { Table, Button, Input, Popconfirm, Tag, Dropdown, Menu } from "antd";
import { Link } from "@reach/router";
//import firebase from "../../Firebase";
import { navigate } from "@reach/router";
import { AiOutlineStock } from "react-icons/ai";
import {
  ExpandAltOutlined,
  ShareAltOutlined,
  CopyOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { Search } = Input;

export default class TemplatePage extends Component {
  constructor(props) {
    super(props);

    this.columnsDetails = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        align: "left",
        title: "Age",
        dataIndex: "value",
        key: "value",
      },
    ];
    this.columns = [
      {
        dataIndex: "favorite",
        key: "favorite",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        align: "left",
        title: "Creator",
        dataIndex: "user",
        key: "user",
      },
      {
        align: "left",
        title: "Last modified",
        dataIndex: "modified",
        key: "modified",
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (user, key) => {
          var isAdmin = this.props.user === key.user ? false : true;
          return (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={(e) => this.handleDelete(e, key.infographicID)}
            >
              <Button disabled={isAdmin} type="primary">
                Delete
              </Button>
            </Popconfirm>
          );
        },
      },
    ];
    this.state = {
      infographicsList: null,
      loading: true,
      data: null,
      infographicName: null,
      imageUrl: null,
      infographicObj: null,
    };
    this.onSelectRow = this.onSelectRow.bind(this);
  }
  /* 
  handleDelete = (e, infographicID) => {
    e.preventDefault();
    const rootId = this.props.rootId;
    const ref = firebase
      .database()
      .ref(`${this.props.pageName}/${this.props.rootId}/${infographicID}`);
    ref.remove(function(error) {
      if (error) {
        // The write failed...
        message.info("Failed to delete item!");
      } else {
        // Data saved successfully!
        notification.open({
          message: "Successfully deleted item",
          description: `Infographic with ID: ${infographicID} was deleted successfully.`,
          duration: 5
        });
      }
    });
  }; */

  onSelectRow(infographicObj) {
    this.props.getInfographicObj(infographicObj);
    navigate(`/infographics/${infographicObj.infographicID}`);
  }

  handleDetails(infographicObj) {
    this.menu = (
      <Menu onClick={(e) => this.handlePermission(e, infographicObj)}>
        <Menu.Item>
          <span>Private</span>
        </Menu.Item>
        <Menu.Item>
          <span>Public</span>
        </Menu.Item>
      </Menu>
    );
    const data = [
      {
        key: "1",
        name: "Creator",
        value: `${
          this.props.user === infographicObj.user ? "you" : infographicObj.user
        }`,
      },
      {
        key: "2",
        name: "Permission",
        value: (
          <Dropdown
            disabled={this.props.user === infographicObj.user ? false : true}
            overlay={this.menu}
            placement="bottomLeft"
          >
            <Button>{`${
              infographicObj.public === false ? "private" : "public"
            }`}</Button>
          </Dropdown>
        ),
      },
      {
        key: "3",
        name: "Created On",
        value: infographicObj.created,
      },
      {
        key: "4",
        name: "Last modified",
        value: infographicObj.modified,
      },
      {
        key: "5",
        name: "Tags",
        value: (
          <span>
            {infographicObj.tags.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </span>
        ),
      },
    ];
    this.setState({
      data,
      infographicName: infographicObj.name,
      imageUrl: infographicObj.imageUrl,
      infographicObj,
    });
  }
  handlePermission(e, infographicObj) {}
  componentDidMount() {}
  render() {
    const { infoRightBar } = this.props;
    var heightRightBar = infoRightBar - 117;
    return (
      <>
        <header className="info-header">
          <div className="info-header-name-button">
            <h2>{this.props.pageName}</h2>
            <Link to="/infographics/new">
              <Button
                type="primary"
                disabled={this.props.disabledButton}
                shape="circle"
                onClick={this.showDrawer}
              >
                <PlusOutlined />
              </Button>
            </Link>
          </div>
        </header>
        <div className="info-content-wrapper">
          <div className="info-content-left">
            <section className="content-left-header">
              <div className="content-left-header-wrapper">
                <div className="search-controls">
                  <Search placeholder="Search " style={{ width: 300 }} />
                </div>
                <div className="mode-controls"></div>
              </div>
            </section>
            <section className="content-left-wrapper">
              {/* onRowClick={this.onSelectRow} */}
              <Table
                columns={this.columns}
                dataSource={this.state.infographicsList}
                pagination={true}
                fixed={false}
                //loading={this.state.loading}
                columnWidth={"130px"}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.handleDetails(record);
                    }, // click row
                    onDoubleClick: (event) => {
                      this.onSelectRow(record);
                    }, // double click row
                    onContextMenu: (event) => {}, // right button click row
                  };
                }}
              />
            </section>
          </div>
          <div className="info-content-right divider">
            <div
              className="info-right-wrapper"
              style={{ width: "100%", height: heightRightBar + "px" }}
            >
              <div className="prev-image-wrapper">
                <h4 className="prev-info-name">
                  <p>
                    <AiOutlineStock
                      style={{
                        backgroundColor: " rgb(245, 245, 245)",
                        fontSize: "24px",
                        marginRight: "16px",
                      }}
                    />
                  </p>
                  <p className="infographic-name">
                    {this.state.infographicName !== null
                      ? this.props.pageName
                      : this.props.pageName}
                  </p>
                </h4>
                <div className="image-display">
                  {this.props.imageUrl !== null ? (
                    <img
                      style={{
                        width: this.props.width,
                        height: this.props.height,
                      }}
                      src={`${this.props.defaultImageUrl}`}
                      alt={`New ${this.props.pageName}`}
                    />
                  ) : (
                    <img
                      style={{ width: "100%", height: "200px" }}
                      src="../../img/infographicImg.jpg"
                      alt={`New ${this.props.pageName}`}
                    />
                  )}
                </div>
                {this.state.data && (
                  <>
                    <div className="info-button">
                      <Button
                        size={"middle"}
                        style={{ width: "140px" }}
                        onClick={(event) => {
                          this.onSelectRow(this.state.infographicObj);
                        }}
                        type="primary"
                        ghost
                      >
                        <ExpandAltOutlined /> Open
                      </Button>
                      <Button
                        size={"middle"}
                        style={{ width: "140px" }}
                        type="primary"
                        ghost
                      >
                        <ShareAltOutlined /> Share
                      </Button>
                      <Button
                        size={"middle"}
                        style={{ width: "140px" }}
                        type="primary"
                        ghost
                      >
                        <CopyOutlined /> Duplicate
                      </Button>
                    </div>
                    <h3 className="details">Details</h3>

                    <Table
                      columns={this.columnsDetails}
                      dataSource={this.state.data}
                      pagination={false}
                      fixed={false}
                      showHeader={false}
                      loading={this.state.loading}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
