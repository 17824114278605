import React, { Component } from "react";
import {
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
  Hint,
  FlexibleXYPlot,
} from "react-vis";
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

function timeConverter(UNIX_timestamp, sum) {
  /* moment(UNIX_timestamp).format("HH-DD/MM/YY"), */
  return {
    x: moment.unix(UNIX_timestamp / 1000).format("HH-DD/MM/YY"),
    y: sum.toFixed(2),
  };
}

export default class EnergyUsedWeekToDateChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightedHour: null,
      hoveredCell: false,
      value: null,
    };
  }
  dateFormatter = (item) => moment(item).format("HH-DD/MM/YY");

  render() {
    const { highlightedHour, hoveredCell, value } = this.state;
    const dataArr = this.props.data.map((d, i) => {
      return {
        x: d.timestamp,
        y: d.sum,
        ...d,
        color: d.timestamp === highlightedHour ? "#19CDD7" : "#125C77",
      };
    });
    return (
      <FlexibleXYPlot height={310}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <YAxis title="kWh/h" />
        <VerticalBarSeries
          colorType="literal"
          onValueMouseOver={(v) =>
            this.setState({ value: timeConverter(v.x, v.y), hoveredCell: true })
          }
          data={dataArr}
          onValueMouseOut={(v) => this.setState({ hoveredCell: false })}
          style={{ cursor: "pointer" }}
          onNearestX={(d) => this.setState({ highlightedHour: d.timestamp })}
        />

        <XAxis
          tickFormat={(d) => this.dateFormatter(d)}
          title="Time, h"
          tickLabelAngle={-25}
          style={{
            line: { stroke: "#ADDDE1" },
            ticks: { stroke: "#ADDDE1" },
            text: {
              stroke: "none",
              fill: "#6b6b76",
              fontSize: "8px",
              fontWeight: 600,
            },
          }}
        />
        {hoveredCell ? <Hint value={value}></Hint> : null}
      </FlexibleXYPlot>
    );
  }
}
