import React, { Component } from "react";
import { Model3DViewer } from "@cognite/gearbox";
/* 
const floors = 
['U2', 'U1', '1', '2', '3',  '4', '5', '6', '7', '8', '9', '10', '11'] */
//const slice = { y: { coord: 1350, direction: false } }
export default class Model3DView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slider: {
        x: { min: -6000, max: 2160 },
        y: { min: -5000, max: 0 },
        z: { min: -6000, max: -80 },
      },
    };
    //this.handleSizeChange = this.handleSizeChange.bind(this);
  }

  handleSizeChange = (e) => {
    //const { test } = this.state;
    //const floor = e.target.value;
    //test = { y: { coord: 200, direction: false } };
    this.setState({ test: { y: { coord: 7951, direction: false } } });
    /*     switch (floor) {
      case "U2":
      const U2 = { y: { coord: 200, direction: false } };
      this.setState({slice: U2})
        break;
      case "U1":
      const U1 = { y: { coord: 550, direction: false } };
      this.setState({slice: U1})
        break;
      case "1":
      const F1 = { y: { coord: 1050, direction: false } };
      this.setState({slice: F1})
        break;
      case "2":
      const F2 = { y: { coord: 1350, direction: false } };
      this.setState({slice: F2})
        break;
      case "3":
      const F3 = { y: { coord: -1700, direction: false } };
      this.setState({slice: F3})
        break;
      case "4":
      const F4 = { y: { coord: 2050, direction: false } };
      this.setState({slice: F4})
        break;
      case "5":
      const F5 = { y: { coord: 2400, direction: false } };
      this.setState({slice: F5})
        break;
      case "6":
      const F6 = { y: { coord: 2750, direction: false } };
      this.setState({slice: F6})
        break;
      case "7":
      const F7 = { y: { coord: 3100, direction: false } };
      this.setState({slice: F7})
        break;
      case "8":
      const F8 = { y: { coord: 3450, direction: false } };
      this.setState({slice: F8})
        break;
      case "9":
      const F9 = { y: { coord: 3800, direction: false } };
      this.setState({slice: F9})
        break;
      case "10":
      const F10 = { y: { coord: 4150, direction: false } };
      this.setState({slice: F10})
        break;
      case "11":
      const F11 = { y: { coord: 4500, direction: false } };
      this.setState({slice: F11})
        break;
      default:
      console.log("Not Found ")
        break;
    } */
    //console.log("slice ", this.state.slice)
  };
  onScreenshot = (url: string) => {
    const downloadElm = document.createElement("a");
    downloadElm.href = url;
    downloadElm.download = "model_3D_viewer_screen_shot";
    downloadElm.click();
  };
  onChange = (val: number, axisNumber: number) => {
    /*     this.planes[axisNumber].set(
      this.planes[axisNumber].normal,
      this.flipped[axisNumber] ? val : -val
    );
    if (this.viewer) {
      this.viewer.setSlicingPlanes(this.planes);
    } */
  };
  render() {
    const { contentWidth } = this.props;
    const { slider } = this.state;
    return (
      <>
        <div
          style={{
            position: "fixed",
            height: "100%",
            width: contentWidth + "px",
          }}
        >
          <Model3DViewer
            showScreenshotButton={true}
            modelId={this.props.model3DdataObject.modelId}
            revisionId={this.props.model3DdataObject.revisionId}
            slider={slider}
            onScreenshot={this.onScreenshot}
          />
        </div>
        {/*       <div style={{  marginLeft: '31%', marginTop: '2%' }}>
        <Radio.Group onChange={this.handleSizeChange}>
        {floors && floors.map((item)=>(
          <Radio.Button key={item} value={item}>{item}</Radio.Button>
        ))}
        </Radio.Group>
      </div> */}
      </>
    );
  }
}
