import React, { Component } from "react";
import { Layout, Table, Button, DatePicker, Tabs, Drawer } from "antd";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TimeseriesChart, AssetMeta } from "@cognite/gearbox";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
  DropDownListComponent,
} from "@syncfusion/ej2-react-dropdowns";

import SystemStatus from "./SystemStatus";
import EnergyUsedWeekToDateChart from "./EnergyUsedWeekToDateChart";
import HeaderMainCustomBoard from "./HeaderMainCustomBoard";
import Board from "./Board";
import { table320columns } from "./const";
import { table350columns } from "./const";
import { table360columns } from "./const";
import { table370columns } from "./const";
import { cmbMultiselectSensorsForSystem320 } from "./const";
import { cmbMultiselectSensorsForSystem350 } from "./const";
import { cmbMultiselectSensorsForSystem360 } from "./const";
import { cmbMultiselectSensorsForSystem370 } from "./const";

import { getWeekListByGivenYear } from "../ClientHelperFun";
import { energyUseWeekToDate } from "../ClientHelperFun";
import { getRtSensorsForEachSystem } from "../ClientHelperFun";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Footer } = Layout;

const styleSystem = {
  backgroundColor: "white",
  paddingBottom: "12%",
  marginBottom: "25px",
  width: "100%",
  borderRadius: "7px",
};
const NO_YEARS = 10;

export default class MainContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetExternalId: null,
      externalId: null,
      defaultYear: moment().format("Y"),
      defaultWeek: moment().format("W"),
      weekList: [],
      yearList: [],
      value: [],
      assetId: null,
      startDate: Date.now() - 365 * 24 * 60 * 60 * 1000,
      endDate: Date.now(),
      board: "EEP",
      showDrawer: false,
      loading: null,
      timeseriesDetails: null,

      timeseriesList: null,

      cmbMultiSelectedExternalId: [],
      customEnergyUsedData: null,
      systemDatapoints: null,

      tblSystem320: null,
      tblSystem350: null,
      tblSystem360: null,
      tblSystem370: null,

      cmbSensor320: null,
      cmbSensor350: null,
      cmbSensor360: null,
      cmbSensor370: null,

      cmbMultiSelectSensorsForSystem320List: [],
      cmbMultiSelectSensorsForSystem350List: [],
      cmbMultiSelectSensorsForSystem360List: [],
      cmbMultiSelectSensorsForSystem370List: [],
    };
    this.cmbSelectedYear = this.cmbSelectedYear.bind(this);
    this.cmbSelectedWeek = this.cmbSelectedWeek.bind(this);
    this.getRaportPerWeek = this.getRaportPerWeek.bind(this);
    this.cmbUnselectedExternalId = this.cmbUnselectedExternalId.bind(this);
    this.cmbMultiSelectedExternalId = this.cmbMultiSelectedExternalId.bind(
      this
    );
    this.cmbSelectSensors = this.cmbSelectSensors.bind(this);
    this.cmbRemoveSensors = this.cmbRemoveSensors.bind(this);
    this.getSystemDatapointsBySensor = this.getSystemDatapointsBySensor.bind(
      this
    );
  }
  static getDerivedStateFromProps(props, state) {
    return {
      tblSystem320: props.tblSystem320,
      tblSystem350: props.tblSystem350,
      tblSystem360: props.tblSystem360,
      tblSystem370: props.tblSystem370,
      systemDatapoints: props.systemDatapoints,
      loading: props.loading,
    };
  }
  handlePanelChange = (value) => {
    this.setState({
      startDate: value[0],
      endDate: value[1],
      value,
    });
  };

  handleChange = (value) => {
    this.setState({
      startDate: value[0],
      endDate: value[1],
      value,
    });
  };
  componentDidMount() {
    const { yearList, defaultYear } = this.state;
    for (var i = 0; i < NO_YEARS; i++) {
      var fullYearNow = moment().format("Y");
      yearList.push(fullYearNow - i);
    }
    this.setState({ weekList: getWeekListByGivenYear(defaultYear) });
  }

  cmbSelectedYear(event) {
    this.setState({
      defaultYear: event.itemData.value,
      weekList: getWeekListByGivenYear(event.itemData.value),
      defaultWeek: moment().format("W"),
    });
  }

  cmbSelectedWeek(event) {
    this.setState({
      defaultWeek: event.itemData.value,
    });
  }

  cmbUnselectedExternalId(e) {
    const { cmbMultiSelectedExternalId } = this.state;
    var lists = cmbMultiSelectedExternalId.filter((x) => {
      return x.externalId !== e.itemData.externalId;
    });
    this.setState({
      cmbMultiSelectedExternalId: lists,
    });
  }

  cmbMultiSelectedExternalId(e) {
    const { cmbMultiSelectedExternalId } = this.state;
    cmbMultiSelectedExternalId.push({ externalId: e.itemData.externalId });
  }

  async getRaportPerWeek(externalId) {
    const { defaultWeek, defaultYear, cmbMultiSelectedExternalId } = this.state;

    if (cmbMultiSelectedExternalId.length === 0) {
      this.setState({
        customEnergyUsedData: await energyUseWeekToDate(
          this.props.client,
          defaultWeek,
          defaultYear,
          this.props.externalIdListBySelectedBuilding
        ),
        assetExternalId: externalId,
      });
    } else {
      this.setState({
        customEnergyUsedData: await energyUseWeekToDate(
          this.props.client,
          defaultWeek,
          defaultYear,
          cmbMultiSelectedExternalId
        ),
        assetExternalId: externalId,
      });
    }
  }

  cmbSelectSensors(event) {
    const {
      cmbMultiSelectSensorsForSystem320List,
      cmbMultiSelectSensorsForSystem350List,
      cmbMultiSelectSensorsForSystem360List,
      cmbMultiSelectSensorsForSystem370List,
      systemDatapoints,
    } = this.state;
    const onSystem = event.itemData.system;

    switch (onSystem) {
      case "320":
        cmbMultiSelectSensorsForSystem320List.push(event.itemData.value);
        this.setState({
          cmbSensor320: getRtSensorsForEachSystem(
            onSystem,
            cmbMultiSelectSensorsForSystem320List,
            systemDatapoints
          ),
        });
        break;

      case "350":
        cmbMultiSelectSensorsForSystem350List.push(event.itemData.value);
        this.setState({
          cmbSensor350: getRtSensorsForEachSystem(
            onSystem,
            cmbMultiSelectSensorsForSystem350List,
            systemDatapoints
          ),
        });
        break;

      case "360":
        cmbMultiSelectSensorsForSystem360List.push(event.itemData.value);
        this.setState({
          cmbSensor360: getRtSensorsForEachSystem(
            onSystem,
            cmbMultiSelectSensorsForSystem360List,
            systemDatapoints
          ),
        });
        break;

      case "370":
        cmbMultiSelectSensorsForSystem370List.push(event.itemData.value);
        this.setState({
          cmbSensor370: getRtSensorsForEachSystem(
            onSystem,
            cmbMultiSelectSensorsForSystem370List,
            systemDatapoints
          ),
        });
        break;

      default:
        console.log("value of i is not equal to any given values");
        break;
    }
  }

  cmbRemoveSensors(event) {
    const {
      cmbMultiSelectSensorsForSystem320List,
      cmbMultiSelectSensorsForSystem350List,
      cmbMultiSelectSensorsForSystem360List,
      cmbMultiSelectSensorsForSystem370List,
      systemDatapoints,
    } = this.state;
    const onSystem = event.itemData.system;

    switch (onSystem) {
      case "320":
        let tmpList320 = cmbMultiSelectSensorsForSystem320List.filter((x) => {
          return x !== event.itemData.value;
        });
        this.setState({
          cmbMultiSelectSensorsForSystem320List: tmpList320,
        });

        this.setState({
          cmbSensor320: getRtSensorsForEachSystem(
            onSystem,
            tmpList320,
            systemDatapoints
          ),
        });
        break;

      case "350":
        let tmpList350 = cmbMultiSelectSensorsForSystem350List.filter((x) => {
          return x !== event.itemData.value;
        });
        this.setState({
          cmbMultiSelectSensorsForSystem350List: tmpList350,
        });
        this.setState({
          cmbSensor350: getRtSensorsForEachSystem(
            onSystem,
            tmpList350,
            systemDatapoints
          ),
        });
        break;

      case "360":
        console.log(
          "cmbMultiSelectSensorsForSystem360List ",
          cmbMultiSelectSensorsForSystem360List
        );
        let tmpList360 = cmbMultiSelectSensorsForSystem360List.filter((x) => {
          return x !== event.itemData.value;
        });
        this.setState({
          cmbMultiSelectSensorsForSystem360List: tmpList360,
        });
        this.setState({
          cmbSensor360: getRtSensorsForEachSystem(
            onSystem,
            tmpList360,
            systemDatapoints
          ),
        });
        break;

      case "370":
        let tmpList370 = cmbMultiSelectSensorsForSystem370List.filter((x) => {
          return x !== event.itemData.value;
        });
        this.setState({
          cmbMultiSelectSensorsForSystem370List: tmpList370,
        });
        this.setState({
          cmbSensor370: getRtSensorsForEachSystem(
            onSystem,
            tmpList370,
            systemDatapoints
          ),
        });
        break;

      default:
        console.log("value of i is not equal to any given values");
        break;
    }
  }

  getSystemDatapointsBySensor(event) {
    this.props.getDatapointsWithSelectedSensor(
      event.itemData.value,
      event.itemData.text,
      this.props.systemDatapoints
    );
  }
  onSelect = (item) => {
    console.log("eee", item);
    this.setState({
      externalId: item.id,
      assetId: this.props.assetId,
      showDrawer: true,
      timeseriesDetails: item,
    });
  };
  onClose = () => {
    this.setState({
      showDrawer: false,
    });
  };

  onChange = (e) => {
    this.setState({ board: e.target.value });
  };

  render() {
    const {
      startDate,
      value,
      endDate,
      customEnergyUsedData,
      assetExternalId,
      timeseriesDetails,
    } = this.state;

    const {
      buildingEnergyValue,
      indorClimateValue,
      systemHealthValue,
      overallScoreValue,

      energyUsedData,
      externalIdListBySelectedBuilding,
      externalId,

      OutdoorTemperatur,
      OutdoorHumidity,
      Percipitationlasthour,
      assetId,
    } = this.props;

    const energyUsedDataChart =
      customEnergyUsedData === null || assetExternalId !== externalId
        ? energyUsedData
        : customEnergyUsedData;

    return (
      <>
        <HeaderMainCustomBoard onChange={this.onChange} assetId={assetId} />
        {this.state.board === "EEP" ? (
          <div className="content-wrapper">
            <Row>
              <Col lg={6}>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <div style={styleSystem}>
                      <SystemStatus
                        systemName={"Energy Status"}
                        inPercentageValue={buildingEnergyValue}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <div style={styleSystem}>
                      <SystemStatus
                        systemName={"Indor Climate Status"}
                        inPercentageValue={indorClimateValue}
                        color={"green"}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <div style={styleSystem}>
                      <SystemStatus
                        systemName={"System Health Status"}
                        inPercentageValue={systemHealthValue}
                        color={"blue"}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <div style={styleSystem}>
                      <SystemStatus
                        systemName={"Overall Score Status"}
                        inPercentageValue={overallScoreValue}
                        color={"orange"}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="section-wrapper">
                      <p
                        style={{
                          color: "black",
                          textAlign: "center",
                          fontSize: 16,
                          marginTop: "16px",
                        }}
                      >
                        Outdoor
                      </p>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5}>
                          <table className="tblOutdoor">
                            <tr>
                              <th>Sunrise</th>
                              <th>Sunset</th>
                            </tr>
                            <tr>
                              <td>03:51</td>
                              <td>22:44</td>
                            </tr>
                          </table>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={7}>
                          <table className="tblOutdoor">
                            <tr>
                              <th>Sensor</th>
                              <th>Now</th>
                              <th>Forcast 6h</th>
                            </tr>
                            <tr>
                              <td>Outdoor Temperatur</td>
                              <td>{OutdoorTemperatur} ºC</td>
                              <td>+4.2 ºC</td>
                            </tr>
                            <tr>
                              <td>Outdoor Humidity</td>
                              <td>{OutdoorHumidity} %</td>
                              <td>38 %</td>
                            </tr>
                            <tr>
                              <td>Nedbør</td>
                              <td>{Percipitationlasthour} mm</td>
                              <td>3.5 mm</td>
                            </tr>
                          </table>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} lg={12} xl={6}>
                    <div className="section-wrapper">
                      <Row>
                        <Col sm md={12}>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              textAlign: "center",
                              fontSize: 14,
                              marginTop: "16px",
                            }}
                          >
                            370 - Komfortkjøling
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "25px" }}>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <DropDownListComponent
                            fields={{ text: "text", value: "value" }}
                            change={this.filterSelect}
                            id="ddlelement"
                            dataSource={null}
                            placeholder={"370"}
                          />
                        </Col>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <MultiSelectComponent
                            id="checkbox"
                            showDropDownIcon={true}
                            dataSource={cmbMultiselectSensorsForSystem370}
                            select={this.cmbSelectSensors}
                            removed={this.cmbRemoveSensors}
                            allowFiltering={true}
                            fields={cmbMultiselectSensorsForSystem370}
                            placeholder="Sensors"
                            mode="CheckBox"
                            selectAllText="All"
                            unSelectAllText="unSelect"
                            showSelectAll={true}
                          >
                            <Inject services={[CheckBoxSelection]} />
                          </MultiSelectComponent>
                        </Col>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <DropDownListComponent
                            width={"100%"}
                            select={this.getSystemDatapointsBySensor}
                            fields={this.state.cmbSensor370}
                            dataSource={this.state.cmbSensor370}
                            id="ddlelement"
                            placeholder=" RT401"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Table
                            columns={table370columns}
                            dataSource={this.state.tblSystem370}
                            pagination={true}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            onRowClick={this.onSelect}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={6}>
                    <div className="section-wrapper">
                      <Row>
                        <Col md={12}>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              textAlign: "center",
                              fontSize: 14,
                              marginTop: "16px",
                            }}
                          >
                            360 - Ventilasjon
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "25px" }}>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <DropDownListComponent
                            fields={{ text: "text", value: "value" }}
                            select={this.filterSelect}
                            id="ddlelement"
                            dataSource={null}
                            placeholder={"360"}
                          />
                        </Col>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <MultiSelectComponent
                            id="checkbox"
                            showDropDownIcon={true}
                            dataSource={cmbMultiselectSensorsForSystem360}
                            select={this.cmbSelectSensors}
                            removed={this.cmbRemoveSensors}
                            allowFiltering={true}
                            fields={cmbMultiselectSensorsForSystem360}
                            placeholder="Sensors"
                            mode="CheckBox"
                            selectAllText="All"
                            unSelectAllText="unSelect"
                            showSelectAll={true}
                          >
                            <Inject services={[CheckBoxSelection]} />
                          </MultiSelectComponent>
                        </Col>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <DropDownListComponent
                            width={"100%"}
                            select={this.getSystemDatapointsBySensor}
                            fields={this.state.cmbSensor360}
                            dataSource={this.state.cmbSensor360}
                            id="ddlelement"
                            placeholder=" RT401"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Table
                            columns={table360columns}
                            dataSource={this.state.tblSystem360}
                            pagination={true}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            onRowClick={this.onSelect}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} lg={12} xl={6}>
                    <div className="section-wrapper">
                      <Row>
                        <Col md={12}>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              textAlign: "center",
                              fontSize: 14,
                              marginTop: "16px",
                            }}
                          >
                            350 - Prosesskjøling
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "25px" }}>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <DropDownListComponent
                            fields={{ text: "text", value: "value" }}
                            change={this.filterSelect}
                            id="ddlelement"
                            dataSource={null}
                            placeholder={"350"}
                          />
                        </Col>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <MultiSelectComponent
                            id="checkbox"
                            showDropDownIcon={true}
                            dataSource={cmbMultiselectSensorsForSystem350}
                            select={this.cmbSelectSensors}
                            removed={this.cmbRemoveSensors}
                            allowFiltering={true}
                            fields={cmbMultiselectSensorsForSystem350}
                            placeholder="Sensors"
                            mode="CheckBox"
                            selectAllText="All"
                            unSelectAllText="unSelect"
                            showSelectAll={true}
                          >
                            <Inject services={[CheckBoxSelection]} />
                          </MultiSelectComponent>
                        </Col>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <DropDownListComponent
                            width={"100%"}
                            select={this.getSystemDatapointsBySensor}
                            fields={this.state.cmbSensor350}
                            dataSource={this.state.cmbSensor350}
                            id="ddlelement"
                            placeholder=" RT401"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Table
                            columns={table350columns}
                            dataSource={this.state.tblSystem350}
                            pagination={true}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            onRowClick={this.onSelect}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={6}>
                    <div className="section-wrapper">
                      <Row>
                        <Col md={12}>
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              textAlign: "center",
                              fontSize: 14,
                              marginTop: "16px",
                            }}
                          >
                            320 - Varme
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "25px" }}>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <DropDownListComponent
                            fields={{ text: "text", value: "value" }}
                            change={this.filterSelect}
                            id="ddlelement"
                            dataSource={null}
                            placeholder={"320"}
                          />
                        </Col>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <MultiSelectComponent
                            id="checkbox"
                            showDropDownIcon={true}
                            dataSource={cmbMultiselectSensorsForSystem320}
                            select={this.cmbSelectSensors}
                            removed={this.cmbRemoveSensors}
                            allowFiltering={true}
                            fields={cmbMultiselectSensorsForSystem320}
                            placeholder="Sensors"
                            mode="CheckBox"
                            selectAllText="All"
                            unSelectAllText="unSelect"
                            showSelectAll={true}
                          >
                            <Inject services={[CheckBoxSelection]} />
                          </MultiSelectComponent>
                        </Col>
                        <Col sm={12} md={4} lg={12} xl={4}>
                          <DropDownListComponent
                            width={"100%"}
                            select={this.getSystemDatapointsBySensor}
                            fields={this.state.cmbSensor320}
                            dataSource={this.state.cmbSensor320}
                            id="ddlelement"
                            placeholder=" RT401"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Table
                            columns={table320columns}
                            dataSource={this.state.tblSystem320}
                            pagination={true}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            onRowClick={this.onSelect}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6}>
                <Row>
                  <Col md={12}>
                    <div
                      style={{
                        borderTop: "3px solid cadetblue",
                        boxShadow: "0 1px 1px rgba(0,0,0,0.1)",
                        backgroundColor: "rgb(255, 255, 255)",
                        borderRadius: "3px",
                        padding: "0px 8px 8px 8px",
                        marginBottom: "35px",
                      }}
                    >
                      <p
                        style={{
                          color: "rgba(0, 0, 0, 0.85)",
                          textAlign: "center",
                          fontSize: 14,
                          marginTop: "16px",
                        }}
                      >
                        Energy use, week to date
                      </p>
                      <Row>
                        <Col md={2}>
                          <DropDownListComponent
                            select={this.cmbSelectedYear}
                            id="ddlelement"
                            dataSource={this.state.yearList}
                            placeholder={`${moment().format("DD.MMM Y")}`}
                          />
                        </Col>
                        <Col md={2}>
                          <DropDownListComponent
                            select={this.cmbSelectedWeek}
                            id="ddlelement"
                            dataSource={this.state.weekList}
                            placeholder={`week: ${this.state.defaultWeek}`}
                          />
                        </Col>
                        <Col md={6}>
                          <MultiSelectComponent
                            id="checkbox"
                            dataSource={externalIdListBySelectedBuilding}
                            select={this.cmbMultiSelectedExternalId}
                            removed={this.cmbUnselectedExternalId}
                            fields={{
                              text: "externalId",
                              value: "externalId",
                            }}
                            placeholder="Select an externalId"
                            mode="CheckBox"
                          >
                            <Inject services={[CheckBoxSelection]} />
                          </MultiSelectComponent>
                        </Col>
                        <Col md={2}>
                          <Button
                            onClick={() => this.getRaportPerWeek(externalId)}
                            className="btnRaport"
                            type="primary"
                          >
                            Rapport
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              width: "100%",
                              marginBottom: "17px",
                              backgroundColor: "white",
                              paddingTop: "16px",
                              borderRadius: "7px",
                            }}
                          >
                            {energyUsedData !== null ? (
                              <EnergyUsedWeekToDateChart
                                data={energyUsedDataChart}
                              />
                            ) : (
                              <div className="showbox">
                                <div className="loader">
                                  <svg
                                    className="circular"
                                    viewBox="25 25 50 50"
                                  >
                                    <circle
                                      className="path"
                                      cx="50"
                                      cy="50"
                                      r="20"
                                      fill="none"
                                      strokeWidth="2"
                                      strokeMiterlimit="10"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {this.state.assetId === this.props.assetId ? (
                    <Drawer
                      title={`${timeseriesDetails.sensor}`}
                      placement="right"
                      closable={false}
                      width={500}
                      onClose={this.onClose}
                      visible={this.state.showDrawer}
                    >
                      <TimeseriesChart
                        height={450}
                        timeseriesIds={[this.state.externalId]}
                        startTime={startDate}
                        endTime={endDate}
                        zoomable={true}
                        contextChart={true}
                      />
                      <div className="grid-wrapper">
                        <div className="display-grid-name">
                          <div className="title">Name </div>
                          {timeseriesDetails.name}
                        </div>
                        <div className="display-grid-name">
                          <div className="title">Latest reading</div>
                          <h3 className="latest-reading">
                            <p className="sensor-value">
                              {timeseriesDetails.value}
                            </p>
                          </h3>
                          {moment(timeseriesDetails.timestamp).format(
                            "MMM DD, YYYY HH:mm:ss"
                          )}
                        </div>
                        <div className="display-grid-name">
                          <div className="title">Id</div>
                          {timeseriesDetails.id}
                        </div>
                        <div className="display-grid-name">
                          <div className="title">ExternalId</div>
                          {timeseriesDetails.externalId}
                        </div>
                      </div>
                    </Drawer>
                  ) : null}

                  <Col md={12}>
                    <div
                      style={{
                        borderTop: "3px solid cadetblue",
                        boxShadow: "0 1px 1px rgba(0,0,0,0.1)",
                        backgroundColor: "rgb(255, 255, 255)",
                        borderRadius: "3px",
                        padding: "0px 8px 8px 8px",
                      }}
                    >
                      <Tabs defaultActiveKey="2">
                        <TabPane tab="Time series" key="1">
                          <Row>
                            <Col sm md={6} lg={6}>
                              <div style={{ marginTop: "35px" }}>
                                <RangePicker
                                  placeholder={["Start month", "End month"]}
                                  value={value}
                                  onChange={this.handleChange}
                                  onPanelChange={this.handlePanelChange}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.85)",
                                  float: "left",
                                  fontSize: 14,
                                  marginTop: "16px",
                                }}
                              >
                                Time series
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <div
                                style={{
                                  width: "100%",
                                  marginBottom: "17px",
                                  paddingTop: "16px",
                                  borderRadius: "7px",
                                }}
                              >
                                {/*  {this.state.assetId === this.props.assetId ? ( */}

                                <div>
                                  <h4
                                    style={{
                                      marginLeft: "230px",
                                      marginTop: "100px",
                                      fontSize: "18px",
                                      fontWeight: "300",
                                    }}
                                  >
                                    Select a time series to view details
                                  </h4>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tab="AssetMeta" key="2">
                          <AssetMeta assetId={assetId} />
                        </TabPane>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ) : (
          <Board
            getTimeseriesObj={this.props.getTimeseriesObj}
            client={this.props.client}
            rootId={this.props.rootId}
            timeseriesList={this.state.timeseriesList}
            getInfographicObj={this.props.getInfographicObj}
            userID={this.props.userID}
            editChart={this.props.editChart}
          />
        )}
        <Footer style={{ textAlign: "center" }}>
          <small>
            &copy; Copyright {moment().format("YYYY")}, Energima Energy Platform
            AS
          </small>
        </Footer>
      </>
    );
  }
}
