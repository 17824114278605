import React, { Component } from "react";
import TemplatePage from "./TemplatePage";

export default class ServiceDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <TemplatePage
          infoRightBar={this.props.infoRightBar}
          pageName={"Service & Doc."}
          disabledButton={true}
          defaultImageUrl={""}
          width={"70%"}
          height={"200px"}
        />
      </div>
    );
  }
}
