import React, { Component } from "react";
import { Menu } from "antd";
import { Link } from "@reach/router";
import "antd/dist/antd.css";
import { FaChartBar, FaBook, FaRecycle } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import { GiGreenhouse } from "react-icons/gi";
import { MdSecurity } from "react-icons/md";

const inlineIcons = {
  marginRight: "7px",
  marginTop: "-3px",
};

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { assetId } = this.props;

    return (
      <>
        {assetId && (
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            style={{ lineHeight: "50px" }}
          >
            <Menu.Item key="1">
              <Link to={`/assets/:${assetId}/SD&EOS`}>
                <FaChartBar style={inlineIcons} /> SD &amp; EOS{" "}
              </Link>
            </Menu.Item>

            <Menu.Item key="2">
              <Link to={`/infographics/${assetId}/indorClimate`}>
                <GiGreenhouse style={inlineIcons} /> Indoor climate{" "}
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to={`/service&doc/${assetId}`}>
                <FaBook style={inlineIcons} /> Service &amp; Doc.{" "}
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to={`/3D/${assetId}`}>
                <AiOutlineGlobal style={inlineIcons} /> 3D{" "}
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to={`/security/${assetId}`}>
                <MdSecurity style={inlineIcons} /> Security{" "}
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to={`/waste-menagement/${assetId}`}>
                <FaRecycle style={inlineIcons} /> Waste Management{" "}
              </Link>
            </Menu.Item>
          </Menu>
        )}
      </>
    );
  }
}
