import React, { Component } from "react";
import TemplatePage from "./TemplatePage";

export default class WasteManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <TemplatePage
        infoRightBar={this.props.infoRightBar}
        pageName={"Waste Management"}
        disabledButton={true}
        defaultImageUrl={"../../img/WasteManagement.png"}
        width={"100%"}
        height={"200px"}
      />
    );
  }
}
