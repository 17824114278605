import React, { Component } from "react";
import { Layout, Select } from "antd";

import { Tabs } from "shineout";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Link, navigate } from "@reach/router";

import SidebarMenu from "./SidebarMenu";
import AssetTree from "./AssetTree/AssetTree";

import { fetchTimeseriesListWithExternalIdPerfix } from "../ClientHelperFun";
import { getExternalIdsOfSysInBuilding } from "../ClientHelperFun";
import { groupByKeyObjectsInArray } from "../ClientHelperFun";
import { generateId } from "../ClientHelperFun";
import { getAssetsByDepth } from "../ClientHelperFun";
import { getAssetsByParentId } from "../ClientHelperFun";
import { getAllAssetTypes } from "../ClientHelperFun";
import { getTypeByExternalId } from "../ClientHelperFun";

const moment = extendMoment(Moment);
const { Sider } = Layout;
const { Option } = Select;

//const { TabPane } = Tabs;
const panelStyle = { padding: "12px 0" };
//const DESCRIPTION = "DESCRIPTION";

export default class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: null,
      weekNumberToday: moment().format("W"),
      year: moment().format("Y"),
      redirectTo: null,
      assetExternalId: null,
      datapointsMultiQuery: null,
      collapsed: false,
      assetId: null,
      AHObjectsGroupedByKey: null,
      assetIds: null,

      initTreeDate: [],
      rootAssetNodes: [],
      cmbValue: "standard",
      rootId: null,
    };
  }
  async componentDidMount() {
    let tmpList = [];
    const assets = await this.props.client.assets
      .list({
        filter: { root: true },
        aggregatedProperties: ["childCount"],
      })
      .autoPagingToArray({ limit: 1000 });
    for (var i = 0; i < assets.length; i++) {
      const isLeaf = assets[i].aggregates
        ? !assets[i].aggregates.childCount
        : false;
      tmpList.push({
        id: assets[i].id,
        externalId: assets[i].externalId,
        parentId: assets[i].parentId,
        description: assets[i].description,
        metadata: assets[i].metadata,
        owner: assets[i].metadata.owner,
        source: assets[i].source,
        lastUpdatedTime: assets[i].lastUpdatedTime,
        createdTime: assets[i].createdTime,
        rootId: assets[i].rootId,
        name: assets[i].name,
        aggregates: assets[i].aggregates,
        title: assets[i].name,
        parentExternalId: assets[i].parentExternalId,
        key: assets[i].id,
        isLeaf: isLeaf,
      });
    }
    tmpList = tmpList.sort((a, b) => {
      if (a.metadata.Adresse < b.metadata.Adresse) return -1;
      if (a.metadata.Adresse > b.metadata.Adresse) return 1;
      return 0;
    });
    this.setState({
      initTreeDate: tmpList,
      rootAssetNodes: tmpList,
    });
  }

  updateTreeData = (list, key, children) => {
    return list.map((node) => {
      if (node.key === Number.parseInt(key, 10)) {
        return { ...node, children };
      }
      if (node.children) {
        return {
          ...node,
          children: this.updateTreeData(node.children, key, children),
        };
      }

      return node;
    });
  };

  onLoadData = async ({ props, children }) => {
    const { cmbValue, AHObjectsGroupedByKey } = this.state;
    let tmpList = [];
    let assets;

    switch (cmbValue) {
      case "owner":
        /*  ========= Schema 1 =========
          Function: - getAssetsByDepth(client, externalIId, depth, childCount),
                   - getAssetsByParentId(client, assetid),
                   - getAHEntriesByIndex(objectsGroupedByKey, title)
          Description: 1. depth takes all assets from depth 0 to 4  
                      2. children takes all assets children with one or more children
                      3. client Cognite Object
                      4. props.eventKey is an assetId
                      5. props.title is name of the owner
      */
        if (!props.externalId) {
          assets = this.getAHEntriesByIndex(AHObjectsGroupedByKey, props.title);
        } else if (props.parentId === undefined) {
          assets = await getAssetsByDepth(
            this.props.client,
            props.externalId,
            4,
            0
          );
        } else if (props.parentId !== undefined) {
          assets = await getAssetsByParentId(this.props.client, props.eventKey);
        }
        break;
      case "standard":
        assets = await getAssetsByParentId(this.props.client, props.eventKey);
        break;
      case "address":
        if (props.parentId === undefined) {
          let types = await getAllAssetTypes();
          for (var t = 0; t < types.length; t++) {
            let typeByIdList = await getTypeByExternalId(
              props.rootId,
              types[t].externalId
            );
            const isLeaf = typeByIdList.length === 0 ? true : false;
            if (!isLeaf) {
              tmpList.push({
                externalId: types[t].externalId,
                name: types[t].name,
                description: types[t].description,
                properties: types[t].properties,
                parentType: types[t].parentType,
                id: types[t].id,
                version: types[t].version,
                createdTime: types[t].createdTime,
                lastUpdatedTime: types[t].lastUpdatedTime,
                title: types[t].name,
                key: types[t].id,
                parentId: props.id,
                isLeaf: isLeaf,
              });
            }
          }
          if (tmpList.length === 0) {
            tmpList.push({ title: "No data found.", isLeaf: true });
          }
          this.setState({ rootId: props.rootId });
          assets = [];
        } else {
          assets = await getTypeByExternalId(
            this.state.rootId,
            props.externalId
          );
        }
        break;

      default:
    }

    for (var i = 0; i < assets.length; i++) {
      const isLeaf = assets[i].aggregates
        ? !assets[i].aggregates.childCount
        : false;
      const isDescription = assets[i].description !== "" ? true : false;
      tmpList.push({
        id: assets[i].id,
        externalId: assets[i].externalId,
        parentId: assets[i].parentId,
        description: assets[i].description,
        metadata: assets[i].metadata,
        source: assets[i].source,
        lastUpdatedTime: assets[i].lastUpdatedTime,
        createdTime: assets[i].createdTime,
        rootId: assets[i].rootId,
        aggregates: assets[i].aggregates,
        title: `${
          isDescription
            ? assets[i].name + " : " + assets[i].description
            : assets[i].name
        }`,
        parentExternalId: assets[i].parentExternalId,
        key: assets[i].id,
        isLeaf: isLeaf,
      });
    }
    tmpList = tmpList.sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
    const initTreeDate = this.updateTreeData(
      this.state.initTreeDate,
      props.eventKey,
      tmpList
    );
    this.setState({
      initTreeDate,
    });
  };
  onCollapse = (collapsed) => {
    this.props.sidebarCollapse(collapsed);
    //console.log("collapsed ", collapsed)
    this.setState({
      collapsed,
    });
  };

  customizeSidebarWidth() {
    this.setState((currentState) => ({
      customizeSidebarWidthToggle: !currentState.customizeSidebarWidthToggle,
    }));
  }

  onSelect = async (assetValue) => {
    const { weekNumberToday, year } = this.state;

    this.props.buildingStatus();
    this.props.assetTreeProps(
      assetValue.props.id,
      assetValue.props.externalId,
      assetValue.props.parentId,
      assetValue.props.rootId
    );

    if (
      assetValue.props.parentId === null ||
      assetValue.props.parentId === undefined
    ) {
      const timeseriesListByExternalIdPrefix = await fetchTimeseriesListWithExternalIdPerfix(
        this.props.client,
        assetValue.props.externalId
      );
      this.props.energyUsed(assetValue.props.externalId, weekNumberToday, year);
      navigate(`/assets/${assetValue.props.id}/SD&EOS`);
      this.props.outdoor(timeseriesListByExternalIdPrefix);

      const externalIdList = getExternalIdsOfSysInBuilding(
        timeseriesListByExternalIdPrefix
      );

      this.props.defaultDatapoints(externalIdList);
    } else {
      navigate(`/assetMeta/${assetValue.props.id}`);
    }
  };

  handleChange = async (value) => {
    const { rootAssetNodes } = this.state;
    let tmpList = [];
    switch (value) {
      case "owner":
        const AHObjectsGroupedByKey = groupByKeyObjectsInArray(
          rootAssetNodes,
          value
        );
        const result = Object.entries(AHObjectsGroupedByKey);
        // console.log(result)
        for (const key in result) {
          const element = result[key];
          if (result.hasOwnProperty(key)) {
            tmpList.push({
              title: element[0],
              key: generateId(10),
            });
          }
        }
        tmpList = tmpList.sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        });
        this.setState({
          initTreeDate: tmpList,
          AHObjectsGroupedByKey,
          cmbValue: value,
        });
        break;
      case "standard":
        this.setState({
          initTreeDate: this.state.rootAssetNodes,
          cmbValue: value,
        });
        break;
      case "address":
        for (var i = 0; i < rootAssetNodes.length; i++) {
          const isLeaf = rootAssetNodes[i].aggregates
            ? !rootAssetNodes[i].aggregates.childCount
            : false;
          tmpList.push({
            id: rootAssetNodes[i].id,
            externalId: rootAssetNodes[i].externalId,
            parentId: rootAssetNodes[i].parentId,
            description: rootAssetNodes[i].description,
            metadata: rootAssetNodes[i].metadata,
            owner: rootAssetNodes[i].metadata.owner,
            source: rootAssetNodes[i].source,
            lastUpdatedTime: rootAssetNodes[i].lastUpdatedTime,
            createdTime: rootAssetNodes[i].createdTime,
            rootId: rootAssetNodes[i].rootId,
            name: rootAssetNodes[i].name,
            aggregates: rootAssetNodes[i].aggregates,
            title:
              rootAssetNodes[i].metadata.Adresse +
              " : " +
              rootAssetNodes[i].metadata.Poststed,
            parentExternalId: rootAssetNodes[i].parentExternalId,
            key: rootAssetNodes[i].id,
            isLeaf: isLeaf,
          });
        }

        this.setState({
          initTreeDate: tmpList,
          cmbValue: value,
        });
        break;
      default:
    }
  };

  getAHEntriesByIndex(objectsGroupedByKey, property) {
    const result = Object.entries(objectsGroupedByKey);
    for (const key in result) {
      const element = result[key];

      if (element[0] === property) {
        return element[1];
      }
    }
    return -1;
  }

  render() {
    const { collapsed } = this.state;
    const { heightOnSidebar, client } = this.props;

    var heightAssetTree = heightOnSidebar - 255;
    return (
      <Sider
        className="ant-layout-sider ant-layout-sider-dark ant-layout-sider-has-trigger "
        style={{ maxHeight: heightOnSidebar + "px" }}
        data-testid="desktop-sidebar"
        width={271}
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        {collapsed === true ? (
          <>
            <div className="logo-small">
              <span className="logo-mini">
                <Link to="/">
                  <img src={this.props.smallLogo} alt="small white logo" />
                </Link>
              </span>
            </div>
            <SidebarMenu client={client} />
          </>
        ) : (
          <div>
            <div className="logo">
              <span className="logo-lg">
                <Link to="/">
                  <img
                    style={{ height: "50px", marginLeft: "15px" }}
                    src={this.props.LargeWhiteLogo}
                    alt="Large white logo"
                  />
                </Link>
              </span>
            </div>
            <Tabs
              shape="line"
              Scroll={false}
              collapsible={false}
              defaultActive={1}
            >
              <Tabs.Panel background="#ddd" style={panelStyle} tab="Settings">
                <SidebarMenu client={client} />
              </Tabs.Panel>

              <Tabs.Panel
                style={{ panelStyle, overflow: "auto" }}
                tab="Navigation"
              >
                <div style={{ margin: "16px" }}>
                  <h4
                    style={{
                      fontSize: "12px",
                      color: "white",
                      marginBottom: "10px",
                    }}
                  >
                    ORGANIZE DATA BY:
                  </h4>
                  <Select
                    defaultValue="standard"
                    style={{ width: "100%" }}
                    onChange={this.handleChange}
                  >
                    <Option value="standard">
                      Standard Building Hierarchy
                    </Option>
                    <Option value="owner">Owner Name</Option>
                    <Option value="address">Physical Building</Option>
                  </Select>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: heightAssetTree + "px",
                    overflow: "auto",
                  }}
                >
                  <AssetTree
                    onSelect={this.onSelect}
                    loadedKeys={[]}
                    onLoadData={this.onLoadData}
                    client={this.props.client}
                    initTreeDate={this.state.initTreeDate}
                  />
                  {/*               <AssetTree 
                    onSelect={this.onSelect}  
                    assetIds={this.state.assetIds}
                    displayName={this.state.selected === DESCRIPTION ? displayNameForDescription : displayNameForName}>
                  </AssetTree> */}
                </div>
              </Tabs.Panel>
            </Tabs>
          </div>
        )}
      </Sider>
    );
  }
}
/* 
function displayNameForName(asset) {
  return asset.name;
}

function displayNameForDescription(asset) {
  return asset.description;
}
 */
