import React from "react";

import { Tree } from "antd";

//const { TreeNode } = Tree;

export default class AssetTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initTreeDate: [],
    };
  }
  onSelectNode = (props) => {
    //const assetId = Number.parseInt(props.eventKey || '', 10);
    const { onSelect } = this.props;
    if (onSelect && props.eventKey) {
      onSelect({ props });
    }
  };
  render() {
    return (
      <Tree
        showLine={false}
        loading={false}
        loadData={this.props.onLoadData}
        loadedKeys={this.props.loadedKeys}
        treeData={this.props.initTreeDate}
        onSelect={(_, e) => this.onSelectNode(e.node.props)}
      />
    );
  }
}
