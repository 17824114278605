import React from "react";

function SystemStatus(props) {
  const SystemName = props.systemName;
  const inPercentageValue = props.inPercentageValue;
  const color = props.color;

  return (
    <div className="inner-content text-center">
      <p className="title-text">
        <em>{SystemName}</em>
      </p>
      <div className={`c100 p${inPercentageValue} center ${color}`}>
        <span>{inPercentageValue}%</span>
        <div className="slice">
          <div className="bar"></div>
          <div className="fill"></div>
        </div>
      </div>
    </div>
  );
}
export default SystemStatus;
