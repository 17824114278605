import React, { Component } from "react";
import { ClientSDKProvider } from "@cognite/gearbox";
import { CogniteClient, REDIRECT } from "@cognite/sdk";
import { Layout } from "antd";
import { Router } from "@reach/router";
import firebase from "./Firebase";

import SideBar from "./components/SideBar";
import Navbar from "./components/Navbar";
import MainContent from "./components/MainContent";
import DefaultContent from "./components/DefaultContent";
import AssetMetaChart from "./components/gearbox/AssetMetaChart";
import Infographics from "./components/infographics/Infographics";
import New from "./components/infographics/New";
import InfographicView from "./components/infographics/InfographicView";
import Timeseries from "./components/timeseriesChart/Timeseries";
import TimeseriesChartNew from "./components/timeseriesChart/New";

import ServiceDoc from "./components/ServiceDoc";
import ThreeDimensional from "./components/ThreeDimensional";
import Model3DView from "./components/model3DView/Model3DView";
import Security from "./components/Security";
import WasteManagement from "./components/WasteManagement";

import { LargeWhiteLogo } from "./components/const";
import { smallLogo } from "./components/const";

import { getBuildingStatus } from "./ClientHelperFun";
import { fetchTimeseriesWithExternalId } from "./ClientHelperFun";
import { energyUseWeekToDate } from "./ClientHelperFun";
import { getRtDataForEachSystem } from "./ClientHelperFun";
import { getDataBySensor } from "./ClientHelperFun";

import "antd/dist/antd.css";
import "./App.css";

const projectId = process.env.REACT_APP_PROJECT_ID;

const { Header } = Layout;
const sidebarWidth = 271; // width in px
const sidebarCollapsedWidth = 80; // width in px
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userID: null,
      usersList: null,
      infographicObj: null,
      timeseriesObj: null,
      sensorObj: null,
      dataSaved: null,
      errorMessageFB: null,
      editChart: null,

      assetId: null,
      externalId: null,
      parentId: "",
      rootId: null,
      timeseriesListByExternalIdPrefix: null,
      systemDatapoints: null,

      buildingEnergyValue: null,
      indorClimateValue: null,
      systemHealthValue: null,
      overallScoreValue: null,

      tblSystem320: null,
      tblSystem350: null,
      tblSystem360: null,
      tblSystem370: null,
      loading: true,

      OutdoorHumidity: null,
      OutdoorTemperatur: null,
      Percipitationlasthour: null,

      energyUsedData: null,
      externalIdListBySelectedBuilding: null,

      height: window.innerHeight,
      contentWidth: window.innerWidth - sidebarWidth,

      model3DdataObject: null,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.updateDimensions);
    const client = new CogniteClient({ appId: "Energima Energy Platform" });

    client.loginWithOAuth({
      project: projectId,
      onAuthenticate: REDIRECT,
      onTokens: ({ accessToken }) => {
        fetch(
          "https://us-central1-eep-frontend-v1.cloudfunctions.net/getFirebaseTokenFromCogniteToken?token=" +
            encodeURIComponent(accessToken)
        )
          .then(function (res) {
            if (!res.ok) {
              throw Error((res.code || "") + res.statusText);
            }
            return res;
          })
          .then((res) => {
            return res.json();
          })
          .then((result) => {
            return firebase.auth().signInWithCustomToken(result.token);
          })
          .catch((error) => {
            console.error("Failed to get firebase user!", error);
          });
      },
    });

    await client.authenticate();
    const user = await client.login.status();

    this.setState({ client, user, userID: user.user });
  };

  updateDimensions = (collapsed) => {
    const hightOnSidebar = window.innerHeight;

    const contentWidth =
      collapsed === true
        ? window.innerWidth - sidebarCollapsedWidth
        : window.innerWidth - sidebarWidth;

    this.setState({
      height: hightOnSidebar,
      contentWidth,
    });
  };

  assetTreeProps = async (assetId, externalId, parentId, rootId) => {
    this.setState({
      assetId,
      externalId,
      parentId,
      rootId,
      dataSaved: null,
    });
  };

  buildingStatus = () => {
    let energyValue = getBuildingStatus();
    let indorClimateValue = getBuildingStatus();
    let systemHealthValue = getBuildingStatus();
    let overallScoreValue =
      (energyValue + indorClimateValue + systemHealthValue) / 3;
    this.setState({
      buildingEnergyValue: energyValue,
      indorClimateValue: indorClimateValue,
      systemHealthValue: systemHealthValue,
      overallScoreValue: overallScoreValue.toFixed(0),
      loading: true,
    });
  };

  energyUsed = async (assetExternalId, weekNumberToday, year) => {
    var timeseriesWithExternalId = await fetchTimeseriesWithExternalId(
      assetExternalId,
      this.state.client,
      null
    );
    this.setState({
      energyUsedData: await energyUseWeekToDate(
        this.state.client,
        weekNumberToday,
        year,
        timeseriesWithExternalId
      ),
      externalIdListBySelectedBuilding: timeseriesWithExternalId,
    });
  };

  outdoor = async (timeseriesListWithExternalIds) => {
    var tmpArray = [];
    for (var i = 0; i < timeseriesListWithExternalIds.length; i++) {
      if (
        timeseriesListWithExternalIds[i].externalId.includes(
          "Relativehumidity"
        ) ||
        timeseriesListWithExternalIds[i].externalId.includes(
          "Weather-RT001##Frost"
        ) ||
        timeseriesListWithExternalIds[i].externalId.includes(
          "Percipitationlasthour"
        )
      ) {
        tmpArray.push({
          externalId: timeseriesListWithExternalIds[i].externalId,
        });
      }
    }
    const datapointsValue =
      tmpArray.length !== 0
        ? await this.state.client.datapoints.retrieveLatest(tmpArray)
        : [];
    if (datapointsValue.length !== 0) {
      for (var d = 0; d < datapointsValue.length; d++) {
        if (datapointsValue[d].externalId.includes("Weather-RT002##Frost")) {
          this.setState({
            OutdoorHumidity: datapointsValue[d].datapoints[0].value.toFixed(2),
          });
        } else if (
          datapointsValue[d].externalId.includes("Weather-RT001##Frost")
        ) {
          this.setState({
            OutdoorTemperatur: datapointsValue[d].datapoints[0].value.toFixed(
              2
            ),
          });
        } else if (
          datapointsValue[d].externalId.includes("Percipitationlasthour")
        ) {
          this.setState({
            Percipitationlasthour: datapointsValue[
              d
            ].datapoints[0].value.toFixed(2),
          });
        }
      }
    } else {
      this.setState({
        OutdoorHumidity: 0,
        OutdoorTemperatur: 0,
        Percipitationlasthour: 0,
      });
    }
  };

  defaultDatapoints = async (systemExternalIdList) => {
    const allPromises = [];
    console.log(" systemExternalIdList ", systemExternalIdList);

    systemExternalIdList.forEach((element) => {
      allPromises.push(this.state.client.datapoints.retrieveLatest([element]));
    });
    const systemDatapoints = await Promise.all(allPromises);
    /*     const systemDatapoints =
      systemExternalIdList.length !== 0
        ? await this.state.client.datapoints.retrieveLatest(
            systemExternalIdList
          )
        : []; */
    console.log(" systemDatapoints ", systemDatapoints);
    if (systemDatapoints.length !== 0) {
      this.setState({
        tblSystem320: getRtDataForEachSystem("320", systemDatapoints),
        tblSystem350: getRtDataForEachSystem("350", systemDatapoints),
        tblSystem360: getRtDataForEachSystem("360", systemDatapoints),
        tblSystem370: getRtDataForEachSystem("370", systemDatapoints),
        loading: false,
        systemDatapoints,
      });
    } else {
      this.setState({
        tblSystem320: [],
        tblSystem350: [],
        tblSystem360: [],
        tblSystem370: [],
        systemDatapoints,
      });
    }
  };

  getInfographicObj = (infographicObj) => {
    this.setState({
      infographicObj,
    });
  };

  getTimeseriesObj = (timeseriesObj, sensorObj) => {
    console.log("timeseriesObj, sensorObj ", timeseriesObj, sensorObj);
    this.setState({
      timeseriesObj,
      sensorObj,
    });
  };
  editChart = (item) => {
    this.setState({ editChart: item });
  };

  ThreeDdataObject = (model3DdataObject) => {
    this.setState({
      model3DdataObject,
    });
  };

  getDatapointsWithSelectedSensor = async (
    system,
    sensor,
    systemDatapoints
  ) => {
    switch (system) {
      case "320":
        this.setState({
          tblSystem320: getDataBySensor(system, sensor, systemDatapoints),
        });
        break;

      case "350":
        this.setState({
          tblSystem350: getDataBySensor(system, sensor, systemDatapoints),
        });
        break;

      case "360":
        this.setState({
          tblSystem360: getDataBySensor(system, sensor, systemDatapoints),
        });
        break;

      case "370":
        this.setState({
          tblSystem370: getDataBySensor(system, sensor, systemDatapoints),
        });
        break;

      default:
        console.log("value of i is not equal to any given values");
    }
  };
  render() {
    const {
      assetId,
      externalId,
      timeseriesListByExternalIdPrefix,
      buildingEnergyValue,
      indorClimateValue,
      systemHealthValue,
      overallScoreValue,
      parentId,
    } = this.state;

    return (
      <div className="App">
        <ClientSDKProvider client={this.state.client}>
          {this.state.client && (
            <Layout
              className="ant-layout-overflow ant-layout ant-layout-has-sider"
              style={{ position: "relative" }}
            >
              <SideBar
                smallLogo={smallLogo}
                LargeWhiteLogo={LargeWhiteLogo}
                client={this.state.client}
                heightOnSidebar={this.state.height}
                energyUsed={this.energyUsed}
                assetTreeProps={this.assetTreeProps}
                buildingStatus={this.buildingStatus}
                outdoor={this.outdoor}
                defaultDatapoints={this.defaultDatapoints}
                sidebarCollapse={this.updateDimensions}
              />
              <Layout className="ant-layout">
                <Header style={{ background: "cadetblue", padding: 0 }}>
                  <Navbar assetId={assetId} />
                </Header>
                <Router>
                  <DefaultContent path="/" />
                  <Infographics
                    path={`/infographics/:${this.state.assetId}/indorClimate`}
                    getInfographicObj={this.getInfographicObj}
                    rootId={this.state.rootId}
                    infoRightBar={this.state.height}
                    infographicObj={this.state.infographicObj}
                    user={this.state.userID}
                  />
                  <New
                    path="/infographics/new"
                    heightOnSidebar={this.state.height}
                    user={this.state.userID}
                    rootId={this.state.rootId}
                    client={this.state.client}
                  />
                  {this.state.infographicObj && (
                    <InfographicView
                      path={`/infographics/:${this.state.infographicObj.infographicID}`}
                      infographicObj={this.state.infographicObj}
                      getTimeseriesObj={this.getTimeseriesObj}
                      user={this.state.userID}
                      rootId={this.state.rootId}
                      client={this.state.client}
                    />
                  )}
                  {this.state.model3DdataObject && (
                    <Model3DView
                      path={`/3d/${this.state.model3DdataObject.modelId}/${this.state.model3DdataObject.revisionId}`}
                      model3DdataObject={this.state.model3DdataObject}
                      contentWidth={this.state.contentWidth}
                    />
                  )}
                  {this.state.timeseriesObj && (
                    <Timeseries
                      path={`/timeseries/:${this.state.timeseriesObj.id}`}
                      infoRightBar={this.state.height}
                      timeseriesObj={this.state.timeseriesObj}
                      sensorObj={this.state.sensorObj}
                    />
                  )}
                  <TimeseriesChartNew
                    path="/timeseries/new"
                    infoRightBar={this.state.height}
                    user={this.state.user}
                    rootId={this.state.rootId}
                  />
                  {this.state.editChart && (
                    <TimeseriesChartNew
                      path={`/timeseries/:${this.state.editChart.itemID}`}
                      infoRightBar={this.state.height}
                      user={this.state.user}
                      rootId={this.state.rootId}
                      editChart={this.state.editChart}
                    />
                  )}
                  <MainContent
                    path={`/assets/:${this.state.assetId}/SD&EOS`}
                    externalId={externalId}
                    client={this.state.client}
                    userID={this.state.userID}
                    assetId={assetId}
                    timeseriesListByExternalIdPrefix={
                      timeseriesListByExternalIdPrefix
                    }
                    energyUsedData={this.state.energyUsedData}
                    systemDatapoints={this.state.systemDatapoints}
                    buildingEnergyValue={buildingEnergyValue}
                    indorClimateValue={indorClimateValue}
                    systemHealthValue={systemHealthValue}
                    overallScoreValue={overallScoreValue}
                    OutdoorHumidity={this.state.OutdoorHumidity}
                    OutdoorTemperatur={this.state.OutdoorTemperatur}
                    Percipitationlasthour={this.state.Percipitationlasthour}
                    tblSystem320={this.state.tblSystem320}
                    tblSystem350={this.state.tblSystem350}
                    tblSystem360={this.state.tblSystem360}
                    loading={this.state.loading}
                    tblSystem370={this.state.tblSystem370}
                    cmbSensor320={this.state.cmbSensor320}
                    cmbSensor350={this.state.cmbSensor350}
                    cmbSensor360={this.state.cmbSensor360}
                    cmbSensor370={this.state.cmbSensor370}
                    rootId={this.state.rootId}
                    parentId={parentId}
                    externalIdListBySelectedBuilding={
                      this.state.externalIdListBySelectedBuilding
                    }
                    energyUsed={this.energyUsed}
                    getDatapointsWithSelectedSensor={
                      this.getDatapointsWithSelectedSensor
                    }
                    getTimeseriesObj={this.getTimeseriesObj}
                    getInfographicObj={this.getInfographicObj}
                    editChart={this.editChart}
                  />

                  <ServiceDoc
                    infoRightBar={this.state.height}
                    path={`/service&doc/${assetId}`}
                  />
                  <ThreeDimensional
                    infoRightBar={this.state.height}
                    ThreeDdataObject={this.ThreeDdataObject}
                    client={this.state.client}
                    path={`/3D/${assetId}`}
                  />
                  <Security
                    infoRightBar={this.state.height}
                    path={`/security/${assetId}`}
                  />
                  <WasteManagement
                    infoRightBar={this.state.height}
                    path={`/waste-menagement/${assetId}`}
                  />

                  <AssetMetaChart
                    path={`/assetMeta/:${this.state.assetId}`}
                    rootId={assetId}
                  />
                </Router>
              </Layout>
            </Layout>
          )}
        </ClientSDKProvider>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
}

export default App;
