import React from "react";

function UnitList(props) {
  const units = props.units;
  console.log("units ", units);
  const listItems = units.map((item) => {
    if (item.units === undefined) return null;
    return (
      <p style={{ width: "50px" }} key={item.id}>
        {item.units[item.id]}
      </p>
    );
  });
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {listItems}
    </div>
  );
}
export default UnitList;
