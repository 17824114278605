import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

export async function fetchBuildingAssets(client) {
  return (
    await client.assets
      .list({
        filter: {
          metadata: {
            BindingType: "BACNET",
          },
        },
      })
      .autoPagingToArray()
  ).map(({ id }) => id);
}
export function getBuildingStatus() {
  const rendomNumberY = 100;
  const rendomNumberX = 60;

  return parseInt(
    Math.floor(
      Math.random() * (rendomNumberY - rendomNumberX + 1) + rendomNumberX
    )
  );
}

function getDateOfISOWeek(w, y) {
  var simple = new Date(y, 0, 1 + (w - 1) * 7),
    dow = simple.getDay(),
    ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export async function fetchTimeseriesListWithExternalIdPerfix(
  client,
  externalId
) {
  var externalIdSliced = externalId.slice(3, externalId.length),
    TS_ = "TS_",
    externalIdPrefix = TS_.concat(externalIdSliced);

  const timeseriesList = await client.timeseries
    .list({ externalIdPrefix: externalIdPrefix })
    .autoPagingToArray({ limit: 1000000 });

  return timeseriesList;
}

export async function fetchTimeseriesWithExternalId(
  assetExternalId,
  client,
  selectedTS_list
) {
  var externalIdSliced = assetExternalId.slice(3, assetExternalId.length),
    TS_ = "TS_",
    externalIdPrefix = TS_.concat(externalIdSliced),
    OE_List = [];

  if (selectedTS_list === null || selectedTS_list.length === 0) {
    const timeseriesList = await client.timeseries
      .list({ externalIdPrefix: externalIdPrefix })
      .autoPagingToArray({ limit: 1000000 });

    for (var p = 0; p < timeseriesList.length; p++) {
      if (timeseriesList[p].externalId.includes("-OE")) {
        OE_List.push({
          externalId: timeseriesList[p].externalId,
        });
      }
    }
  } else {
    OE_List = selectedTS_list;
  }
  return OE_List;
}

export async function energyUseWeekToDate(
  client,
  NoWeek,
  year,
  timeseriesList
) {
  //console.log(' timeseriesList ', timeseriesList);
  var dateToday = moment().toDate(),
    unix_time_today = moment(dateToday).unix(),
    unix_time_start = moment()
      .day("Monday")
      .year(year)
      .week(NoWeek)
      .startOf("W")
      .unix(),
    unix_time_end = moment()
      .day("Monday")
      .year(year)
      .week(NoWeek)
      .endOf("W")
      .unix(),
    hours =
      unix_time_end > unix_time_today
        ? (unix_time_today - unix_time_start) / 3600
        : (unix_time_end - unix_time_start) / 3600,
    start_date = new Date(getDateOfISOWeek(NoWeek, year)).getTime(),
    end_date = start_date + hours * 60 * 60 * 1000,
    hour_list = [],
    datapoints_list = [],
    results_values = [];

  for (var i = 0; i < hours; i++) {
    hour_list.push(start_date + i * (60 * 60 * 1000));
  }
  const allPromises = [];
  timeseriesList.forEach((element) => {
    allPromises.push(
      client.datapoints.retrieve({
        items: [element],
        start: start_date,
        end: end_date,
        limit: 350,
        aggregates: ["sum"],
        granularity: "1h",
        includeOutsidePoints: false,
      })
    );
  });
  const data = await Promise.all(allPromises);

  for (let d = 0; d < data.length; d++) {
    data[d][0].datapoints.map((d) => {
      return datapoints_list.push({
        timestamp: d.timestamp,
        sum: d.sum,
      });
    });
  }

  for (var h = 0; h < hour_list.length; h++) {
    var sum = 0;
    for (var dl = 0; dl < datapoints_list.length; dl++) {
      if (
        datapoints_list[dl].timestamp >= hour_list[h] &&
        datapoints_list[dl].timestamp < hour_list[h + 1]
      ) {
        sum += datapoints_list[dl].sum;
      }
    }

    results_values.push({
      timestamp: hour_list[h],
      sum: sum,
    });
  }

  return results_values;
}

export function getWeekListByGivenYear(fullYear) {
  var tmpArray = [],
    dateToday = moment().toDate(),
    currentYear = moment().format("Y"),
    year = moment().startOf("year").format("Y-MM-DD"),
    date = moment(dateToday).format("Y-MM-DD");

  if (currentYear > fullYear) {
    date = fullYear + "-01-01";
    year = fullYear + "-12-31";
  }
  const range = moment.range(year, date);

  for (let week of range.by("week")) {
    tmpArray.push(week.format("W"));
  }
  return tmpArray;
}

export function getExternalIdFromList(str, list) {
  var tempList = [];

  for (var s = 0; s < list.length; s++) {
    if (list[s].externalId.includes(str)) {
      tempList.push({ externalId: list[s].externalId });
    }
  }
  return tempList;
}

function getSplitExternalId(externalId) {
  const splitExternalId = externalId.split("-")[1].substring(0, 7);
  const isRequiredSensor =
    splitExternalId.length === 5
      ? splitExternalId
      : splitExternalId.substring(5, 6) === "#"
      ? splitExternalId.substring(0, 5)
      : "";

  return isRequiredSensor;
}

const BUILDING_SENSOR_TYPE = ["RT", "RP", "JV", "SB"];

export function getExternalIdsOfSysInBuilding(idList) {
  return idList
    .filter((el) =>
      BUILDING_SENSOR_TYPE.includes(
        getSplitExternalId(el.externalId)?.substring(0, 2)
      )
    )
    .map((e) => ({ externalId: e.externalId }));
}

// system 320,350,360,370
export function getRtDataForEachSystem(sysName, dataList) {
  var tmpArray = [];
  console.log("dataList ", dataList);
  for (var s = 0; s < dataList.length; s++) {
    let systemNameFraSplitedExt = dataList[s][0].externalId
      .split("=")[1]
      .split(".")[0];

    let sensorFraSplitedExt = getSplitExternalId(dataList[s][0].externalId);
    let unit = dataList[s][0].unit === "degC" ? "ºC" : dataList[s][0].unit;
    let isUnitUndefined = unit === undefined ? "" : unit;
    var value =
      dataList[s][0].datapoints.length === 0
        ? "No Data"
        : dataList[s][0].datapoints[0].value.toFixed(2);

    if (sysName === systemNameFraSplitedExt) {
      let displayName = dataList[s][0].externalId.split("=")[1];
      displayName = displayName.split("##")[0];
      tmpArray.push({
        id: dataList[s][0].id,
        externalId: dataList[s][0].externalId,
        name: displayName,
        sensor: sensorFraSplitedExt,
        value: value + " " + isUnitUndefined,
        unit: isUnitUndefined,
      });
    }
  }
  console.log(" tmpArray ", tmpArray);
  tmpArray.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (tmpArray = tmpArray.length !== 0 ? tmpArray : []);
}

// system 320,350,360,370, sensor choosen by user
export function getDataBySensor(sysName, sensor, dataList) {
  let tmpArray = [];
  console.log("dataList ", dataList);
  for (var s = 0; s < dataList.length; s++) {
    let systemNameFraSplitedExt = dataList[s][0].externalId
      .split("=")[1]
      .split(".")[0];
    let sensorFraSplitedExt = getSplitExternalId(dataList[s][0].externalId);
    let unit = dataList[s][0].unit === "degC" ? "ºC" : dataList[s][0].unit;
    let displayName = dataList[s][0].externalId.split("=")[1];
    displayName = displayName.split("##")[0];
    let isUnitUndefined = unit === undefined ? null : unit;
    let value =
      dataList[s][0].datapoints.length === 0
        ? "No Data"
        : dataList[s][0].datapoints[0].value.toFixed(2);

    if (sensor === sensorFraSplitedExt && sysName === systemNameFraSplitedExt) {
      tmpArray.push({
        id: dataList[s][0].id,
        externalId: dataList[s][0].externalId,
        name: displayName,
        sensor: sensorFraSplitedExt,
        value: value,
        unit: isUnitUndefined,
      });
    }
  }
  tmpArray.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (tmpArray = tmpArray.length !== 0 ? tmpArray : []);
}

export function getRtSensorsForEachSystem(system, sensorList, dataList) {
  var tmpArray = [];

  for (var i = 0; i < sensorList.length; i++) {
    for (var s = 0; s < dataList.length; s++) {
      let systemNameFromSplitedExt = dataList[s][0].externalId
        .split("=")[1]
        .split(".")[0];
      let sensorFromSplitedExt = getSplitExternalId(dataList[s][0].externalId);
      let twoCharacterOfSensor = sensorFromSplitedExt.substring(0, 2);
      var match = sensorList[i];

      if (
        match === twoCharacterOfSensor &&
        systemNameFromSplitedExt === system
      ) {
        const exists = tmpArray.find(
          (item) => item.text === sensorFromSplitedExt
        );
        if (!exists) {
          tmpArray.push({ value: system, text: sensorFromSplitedExt });
        }
      }
    }
  }
  tmpArray.sort((a, b) => (a.text > b.text ? 1 : -1));

  return tmpArray;
}

export function displayName(user) {
  var lowerCaseName = user && user.split(".")[0];
  var lowerCaseLastName = user && user.split(".")[1];

  var name =
    lowerCaseName &&
    lowerCaseName.charAt(0).toUpperCase() + lowerCaseName.substr(1);
  var lastName =
    lowerCaseLastName &&
    lowerCaseLastName.charAt(0).toUpperCase() + lowerCaseLastName.substr(1);

  var displayName = name + " " + lastName.split("@")[0];

  return displayName;
}

export function getUserInFB(user, list) {
  let tmpList = [];
  for (let item in list) {
    if (user === list[item].user) {
      tmpList.push({
        userID: list[item].userID,
        displayName: list[item].displayName,
        role: list[item].role,
        project: list[item].project,
        projectId: list[item].projectId,
        user: list[item].user,
      });
      return tmpList;
    }
  }
  return false;
}

export function getExternalIdsWithSameTimeseriesIds(
  timeseriesIds,
  externalIds
) {
  let tmpList = [];

  for (let i = 0; i < timeseriesIds.length; i++) {
    var item = externalIds.find((item) => item.id === timeseriesIds[i]);
    tmpList.push(item);
  }

  return tmpList;
}

// Accepts the array and key
export function groupByKeyObjectsInArray(array, key) {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
}

export function generateId(n) {
  var add = 1,
    max = 10 - add;

  if (n > max) {
    return generateId(max) + generateId(n - max);
  }

  max = Math.pow(10, n + add);
  var min = max / 10; // Math.pow(10, n) basically
  var number = Math.floor(Math.random() * (max - min + 1)) + min;
  return number;
}
export async function getAssetsByDepth(
  client,
  externalId,
  givenDepth,
  childCount
) {
  let cursor = null;
  let assetListe = [];

  do {
    let response = await client.assets.list({
      filter: { externalIdPrefix: externalId },
      aggregatedProperties: ["depth", "childCount"],
      limit: 1000,
      cursor: cursor,
    });
    cursor = response.nextCursor;
    for (var l = 0; l < response.items.length; l++) {
      let depth = response.items[l].aggregates.depth;
      let children = response.items[l].aggregates.childCount;

      if (depth === givenDepth && children > childCount) {
        assetListe.push(response.items[l]);
      }
    }
  } while (cursor !== undefined);
  return assetListe;
}

export async function getAssetsByParentId(client, id) {
  const assets = await client.assets
    .list({
      filter: { parentIds: [Number.parseInt(id, 10)] },
      aggregatedProperties: ["childCount"],
    })
    .autoPagingToArray({ limit: 1000 });
  return assets;
}

export async function getAllAssetTypes() {
  var myHeaders = new Headers();
  myHeaders.append(
    "api-key",
    "MzQxNzNjMzEtMGQ1Yi00ZjJlLWFhNDgtNTE2NTAyMmE3OGQ1"
  );
  myHeaders.append("content-type", "application/json");

  var raw = JSON.stringify({
    filter: {
      externalIdPrefix: "",
    },
    limit: 1000,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const resp = await fetch(
      "https://api.cognitedata.com/api/playground/projects/energima/types/list",
      requestOptions
    );
    const arr = await resp.json();
    return arr.items;
  } catch (err) {
    console.log(err);
  }
}

export async function getTypeByExternalId(rootId, typeExternalId) {
  var myHeaders = new Headers();
  myHeaders.append(
    "api-key",
    "MzQxNzNjMzEtMGQ1Yi00ZjJlLWFhNDgtNTE2NTAyMmE3OGQ1"
  );
  myHeaders.append("content-type", "application/json");
  var raw = JSON.stringify({
    filter: {
      rootIds: [
        {
          id: rootId,
        },
      ],
      types: [
        {
          type: {
            externalId: typeExternalId,
            version: 1,
          },
        },
      ],
    },
    limit: 1000,
    aggregatedProperties: ["childCount"],
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const resp = await fetch(
      "https://api.cognitedata.com/api/playground/projects/energima/assets/list",
      requestOptions
    );
    const arr = await resp.json();

    return arr.items;
  } catch (err) {
    console.log(err);
  }
}

export function convertArrayBufferToImage(arrayBuffer) {
  var arrayBufferView = new Uint8Array(arrayBuffer);
  var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
  var urlCreator = window.URL || window.webkitURL;
  var imageUrl = urlCreator.createObjectURL(blob);

  return imageUrl;
}
