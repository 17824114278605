import React, { Component } from "react";
import { Layout } from "antd";
import moment from "moment";

const { Footer } = Layout;

export default class DefaultContent extends Component {
  render() {
    return (
      <>
        <div>
          <img
            style={{ width: "100%", height: "100%" }}
            src="../img/dashboardImg.png"
            alt="Welcome Page"
          />
        </div>
        <Footer style={{ textAlign: "center" }}>
          <small>
            &copy; Copyright {moment().format("YYYY")}, Energima Energy Platform
            AS
          </small>
        </Footer>
      </>
    );
  }
}
