import React from "react";
import { Menu } from "antd";
import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";
import firebase from "../Firebase";

function SidebarMenu({client}) {
    return (
      <div className="e-content">
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={async () => {
                const url = await client.logout.getUrl();
                await firebase.auth().signOut();
                window.location.href = url;
            }}>
                Logout
            </Menu.Item>
        </Menu>
      </div>
    );
}

export default SidebarMenu;
